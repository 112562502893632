#header-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
}

#header-container{
    padding: 20px 0 10px;
    border-bottom: 1px solid $warmGray;
    position: relative;
    z-index: 100000;
    &.non-stoneleigh{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: $white;
        z-index: 100000;
    }
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            //@include align-center;
            >div{
                &:first-child{
                    width: 150px;
                }
                &:last-child{
                    width: calc(100% - 150px);
                    >div{
                        text-align: right;
                        &:first-child{
                            ul{
                                padding: 0;
                                margin: 0;
                                list-style: none;
                                display: inline-block;
                                li{
                                    display: inline-block;
                                    &:last-child{
                                        span:not(.title){
                                            display: none;
                                        }
                                    }
                                    span{
                                        position: relative;
                                        top: -1px;
                                        display: inline-block;
                                        margin: 0 10px;
                                        font-family: $secondaryFont;
                                        &.title{
                                            margin: 0;
                                            font-size: 20px;
                                            font-weight: bold;
                                            color: $brownLight;
                                            font-family: $secondaryFont;
                                            &:hover{
                                                color: $brownLight;
                                            }
                                        }
                                    }
                                    a{
                                        color: $brownLight;
                                        text-decoration: none;
                                        font-weight: normal;
                                        font-family: $secondaryFont;
                                        &:hover{
                                            color: $maroon;
                                        }
                                    }
                                }
                            }
                            #desktop-search-toggle{
                                display: inline-block;
                                text-decoration: none;
                                color: $brownDark;
                                margin-left: 15px;
                                vertical-align: middle;
                                font-size: 20px;
                                &:hover{
                                    color: $brownDark;
                                }
                                .icon-search{
                                    &:before{
                                        content: '\e90e';
                                    }
                                }
                            }
                        }
                        &:last-child{
                            ul{
                                padding: 0;
                                margin: 0;
                                list-style: none;
                                li{
                                    display: inline-block;
                                    position: static;
                                    &.button{
                                        &.red{
                                            margin-left: 90px;
                                            a{
                                                @include red-button;
                                                color: $white;
                                            }
                                        }
                                        &.yellow{
                                            a{
                                                @include yellow-button;
                                                color: $brownDark;
                                            }
                                        }
                                    }
                                    &:not(:last-of-type){
                                        margin-right: 25px;
                                    }
                                    &.menu-item-has-children{
                                        margin-right: 20px;
                                        &:hover{
                                            .mega-menu{
                                                opacity: 1;
                                                visibility: visible;
                                                z-index: 10000;
                                                @include transition(all, 300ms, ease-in-out);
                                            }
                                        }
                                        >a, >span{
                                            padding-bottom: 30px;
                                        }
                                    }
                                    a, span{
                                        font-size: 18px;
                                        color: $brownDark;
                                        font-weight: normal;
                                        text-decoration: none;
                                        position: relative;
                                        &:not([href]){
                                            &:focus{
                                                outline: 1px solid $brownDark;
                                            }
                                        }
                                        &:focus{
                                            outline: 1px solid $brownDark;
                                        }
                                        &:hover{
                                            color: $maroon;
                                        }
                                    }
                                    .mega-menu{
                                        position: absolute;
                                        opacity: 0;
                                        z-index: -1;
                                        visibility: hidden;
                                        top: 100%;
                                        width: 100%;
                                        right: 0;
                                        @include transition(all, 300ms, ease-in-out);
                                        background: $offwhite;
                                        display: flex;
                                        flex-direction: row;
                                        flex-wrap: wrap;
                                        padding: 50px 70px;
                                        >div{
                                            &:first-child{
                                                width: 60%;
                                                text-align: left;
                                                >div{
                                                    display: flex;
                                                    flex-direction: row;
                                                    flex-wrap: wrap;
                                                    >div{
                                                        width: 48%;
                                                        &:nth-of-type(2n){
                                                            margin-left: 4%;
                                                        }
                                                    }
                                                }
                                                ul{
                                                    padding: 0;
                                                    list-style: none;
                                                    margin: 0;
                                                    >li{
                                                        width: 100%;
                                                        margin-bottom: 20px;
                                                        >a{
                                                            font-weight: bold;
                                                            font-size: 20px;
                                                            color: $brownLight;
                                                            font-family: $secondaryFont;
                                                            text-decoration: none;
                                                            &:hover{
                                                                color: $maroon !important;
                                                            }
                                                            .icon-arrow{
                                                                left: 0;
                                                                top: -1px;
                                                                color: $brownDark;
                                                                font-size: 16px;
                                                                position: relative;
                                                                margin-right: 15px;
                                                                display: inline-block;
                                                                vertical-align: middle;
                                                                @include transition(all, 300ms, ease-in-out);
                                                                &:before{
                                                                    content: '\e901';
                                                                }
                                                            }
                                                        }
                                                        ul{
                                                            padding: 10px 0 0;
                                                            margin: 0;
                                                            width: 100%;
                                                            >li{
                                                                width: 100%;
                                                                &:not(:last-of-type){
                                                                    margin: 0 0 10px;
                                                                }
                                                                a{
                                                                    font-weight: 400;
                                                                    font-size: 16px;
                                                                    color: $brownLight;
                                                                }
                                                            }
                                                        }
                                                        p{
                                                            line-height: 1.667;
                                                            margin-top: 5px;
                                                        }
                                                    }
                                                }
                                            }
                                            &:last-child{
                                                width: 40%;
                                                padding-left: 50px;
                                                text-align: left;
                                                a{
                                                    &:hover{
                                                        color: $brownDark;
                                                    }
                                                }
                                                .image-wrapper{
                                                    width: 100%;
                                                    position: relative;
                                                    height: 240px;
                                                    margin-bottom: 20px;
                                                    img{
                                                        height: 240px !important;
                                                        object-fit: cover;
                                                    }
                                                }
                                                p{
                                                    font-family: $primaryFont;
                                                    line-height: 1.667;
                                                    strong{
                                                        font-family: $secondaryFont;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #mobile-search-toggle{
        display: none;
        text-decoration: none;
        color: $brownLight;
        margin-left: 15px;
        vertical-align: middle;
        font-size: 32px;
        position: absolute;
        right: 105px;
        top: 24px;
        &:hover{
            color: $brownDark;
        }
        .icon-search{
            &:before{
                content: '\e90b';
            }
        }
    }
    #mobile-menu-icon{
        display: none;
        position: absolute;
        top: 27px;
        right: 20px;
        width: 60px;
        height: 60px;
        text-decoration: none;
        line-height: 60px;
        color: $brownLight;
        text-align: center;
        &.active{
            span{
                @include transition(all, 300ms, ease-in-out);
                &.icon-menu{
                    opacity: 0;
                }
                &.icon-close{
                    opacity: 1;
                }
            }
        }
        span{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            &.icon-menu{
                font-size: 35px;
                opacity: 1;
                @include transition(all, 300ms, ease-in-out);
                &:before{
                    content: '\e90c';
                }
            }
            &.icon-close{
                font-size: 35px;
                opacity: 0;
                @include transition(all, 300ms, ease-in-out);
                &:before{
                    content: '\e90d';
                }
            }
        }
    }
}

#stoneleigh-header-container{
    background: $blueDark;
    padding: 30px 0;
    z-index: 101;
    &.is-sticky{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        >div{
            >div{
                >div{
                    &:first-child, &:last-child{
                        opacity: 1;
                        @include transition(all, 300ms, ease-in-out);
                    }
                }
            }
        }
    }
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include align-center;
            >div{
                &:first-child{
                    width: 150px;
                    opacity: 0;
                    @include transition(all, 300ms, ease-in-out);
                }
                &:nth-child(2){
                    width: calc(100% - 455px);
                    text-align: center;
                    position: relative;
                    top: 10px;
                    padding: 0 0 0 125px;
                    p{
                        color: $white;
                        font-size: 30px;
                        font-family: $secondaryFont;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                    ul{
                        padding: 0;
                        list-style: none;
                        >li{
                            display: inline-block;
                            padding: 0 10px;
                            &.current_page_item{
                                >a{
                                    text-decoration: underline;
                                }
                            }
                            >a{
                                color: $white;
                                font-size: 18px;
                                text-decoration: none;
                                text-transform: lowercase;
                                font-family: $secondaryFont;
                                opacity: 1;
                                &:hover{
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
                &:last-child{
                    width: 305px;
                    opacity: 0;
                    @include transition(all, 300ms, ease-in-out);
                    ul{
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        >li{
                            display: inline-block;
                            &:not(:last-of-type){
                                margin-right: 25px;
                            }
                            a{
                                @include white-border-button;
                            }
                        }
                    }
                }
            }
        }
    }
}

#mobile-menu{
	display: block;
	position: fixed;
	left: 0;
	top: 112px;
	height: 100%;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	transform: translateX(-100%);
	-webkit-transform: translateX(-100%);	
	transition-property: transform;
	transition-duration: 0.6s;
	z-index: 100000;
	background-color: $white;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	padding: 15px 20px 240px;
	&.is-visible{
		transform: translateX(0%);
		-webkit-transform: translateX(0%);		
	}
    #mobile-menu-main{
        ul{
            margin: 0;
            list-style: none;
            padding: 0;
            li{
                position: relative;
                &.search{
                    display: none;
                }
                &.button{
                    &.red{
                        text-align: center;
                        margin-top: 30px;
                        a{
                            @include red-button;
                            width: 100%;
                            max-width: 500px;
                            font-weight: 500;
                        }
                    }
                    &.yellow{
                        margin-top: 20px;
                        text-align: center;
                        a{
                            @include yellow-button;
                            width: 100%;
                            max-width: 500px;
                            font-weight: 500;
                        }
                    }
                }
                &.menu-item-has-children{
                    >a{
                        position: relative;
                        font-family: $secondaryFont;
                        &.active{
                            color: $brownDark;
                            &:after{
                                @include rotate(-90deg);
                                @include transition(all, 300ms, ease-in-out);
                            }
                        }
                        &:after{
                            position: absolute;
                            content: '\e900';
                            color: $brownDark;
                            top: 14px;
                            right: -30px;
                            font-family: $iconFont;
                            font-size: 16px;
                            @include rotate(90deg);
                            @include transition(all, 300ms, ease-in-out);
                        }
                    }
                }
                a{
                    font-size: 20px;
                    font-family: $secondaryFont;
                    color: $brownLight;
                    font-weight: bold;
                    text-decoration: none;
                    padding: 10px 0;
                    display: inline-block;
                }
                .sub-menu{
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.5s ease-out;
                    border-left: 1px solid $warmGray;
                    width: calc(100% + 40px);
                    position: relative;
                    padding: 0 20px;
                    top: 1px;
                    &.active{
                        padding: 10px 20px;
                        max-height: 700px;
                        transition: all 0.5s ease-in;
                    }
                    ul{
                        padding: 0;
                        margin: 0;
                        li{
                            border: none;
                            padding: 5px 0;
                            a, span{
                                padding: 0;
                                color: $brownDark;
                                text-decoration: none;
                                font-family: $secondaryFont;
                            }
                            p{
                                display: none;
                            }
                        }
                        .grandchildren{
                            padding: 5px 0 10px;
                            >li{
                                padding: 2.5px;
                                a{
                                    font-size: 18px;
                                    font-weight: 400;
                                    color: $brownLight;
                                    font-family: $secondaryFont;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1420px){
    #header-container{
        padding: 20px 20px 10px;
        >div{
            >div{
                >div{
                    &:last-child{
                        >div{
                            &:last-child{
                                ul{
                                    li{
                                        &.button{
                                            &.red{
                                                margin-left: 0;
                                            }
                                        }
                                        .mega-menu{
                                            padding: 50px 20px;
                                            width: calc(100% + 40px) !important;
                                            left: -20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #stoneleigh-header-container{
        padding: 30px 20px;
    }
}

@media screen and (max-width: 1240px){
    #header-wrapper{
        position: relative;
    }
    #header-container{
        padding: 20px;
        &.non-stoneleigh{
            position: relative;
        }
        >div{
            >div{
                >div{
                    &:first-child{
                        width: 150px;
                    }
                    &:last-child{
                        display: none;;
                    }
                }
            }
        }
        #mobile-search-toggle, #mobile-menu-icon{
            display: block;
        }
    }
    #stoneleigh-header-container{
        >div{
            >div{
                >div{
                    &:first-child{
                        display: none;
                    }
                    &:nth-child(2){
                        width: 100%;
                        padding: 0;
                    }
                    &:last-child{
                        width: 100%;
                        padding: 20px 0 0;
                        opacity: 1;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    #header-container{
        padding: 15px 20px;
        >div{
            >div{
                >div{
                    &:first-child{
                        width: 120px;
                    }
                }
            }
        }
        #mobile-search-toggle{
            top: 20px;
        }
        #mobile-menu-icon{
            top: 27px;
            font-size: 26px;
            &.active{
                top: 27px;
            }
            span{
                &.icon-menu{
                    font-size: 26px;
                }
                &.icon-close{
                    font-size: 26px;
                }
            }
        }
    }
    #stoneleigh-header-container{
        padding: 20px;
        >div{
            >div{
                >div{
                    &:nth-child(2){
                        p{
                            font-size: 24px;
                        }
                        ul{
                            margin-bottom: 0;
                            >li{
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    #mobile-menu{
        top: 82px;
    }
}

@media screen and (max-width: 360px){
    #header-container{
        >div{
            >div{
                &:first-child{
                    width: 160px;
                }
            }
        }
    }
    #stoneleigh-header-container{
        >div{
            >div{
                >div{
                    &:last-child{
                        ul{
                            >li{
                                width: 100%;
                                &:not(:last-of-type){
                                    margin: 0 0 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #mobile-menu{
        top: 110px;
    }
}