#visit-header-container{
    background: $maroon;
    padding: 80px 0;
    margin-top: 130px;
    h1, h2, h3, h4, h5, h6, p{
        color: $white;
    }
}

#visit-category-container{
    padding: 40px 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $warmGray;
    >div{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        >div{
            &:not(:last-of-type){
                margin-right: 20px;
            }
            a{
                color: $brownLight;
                text-decoration: none;
                text-transform: lowercase;
                position: relative;
                font-family: $secondaryFont;
                padding-bottom: 16px;
                &.active, &:hover{
                    &:before{
                        -webkit-transform: scale3d(1, 1, 1);
                        transform: scale3d(1, 1, 1);
                        -webkit-transition-delay: 0s;
                        transition-delay: 0s;
                    }
                }
                &:before{
                    background: $brownDark;
                    -webkit-transition-delay: 0.4s;
                    transition-delay: 0.4s;
                    content: '';
                    position: absolute;
                    top: calc(100% + 5px);
                    left: 0;
                    width: 100%;
                    height: 2px;
                    -webkit-transform: scale3d(0, 1, 1);
                    transform: scale3d(0, 1, 1);
                    -webkit-transform-origin: center left;
                    transform-origin: center left;
                    -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                    transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                }
                &:hover{
                    color: $brownDark;
                }
            }
        }
    }
}

#visit-list-container{
    padding: 30px 0 40px;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            margin-bottom: 40px;
            background: $white;
            border: 1px solid $warmGray;
            a{
                text-decoration: none;
                &:hover{
                    .image-wrapper{
                        img{
                            transform: scale(1.15);
							@include transition(all, 500ms, ease-in-out);
                        }
                    }
                }
                h2, p{
                    margin-bottom: 0;
                    color: $brownDark;
                }
                h2{
                    color: $brownLight;
                    margin-bottom: 5px;
                    font-size: 20px;
                }
                p{
                    font-size: 16px;
                }
            }
            .image-wrapper{
                width: 100%;
                height: 220px;
                display: inline-block;
                overflow: hidden;
                img{
                    object-fit: cover;
                    height: 220px !important;
                    transform: scale(1.0);
                    @include transition(all, 500ms, ease-in-out);
                }
            }
            .visit-content{
                padding: 24px;
            }
        }
    }
}

#visit-content-container{
    padding: 0 0 60px;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include align-center;
        >div{
            width: 50%;
            &:first-child{
                padding-right: 40px;
            }
            &:last-child{
                .image-wrapper{
                    height: 300px;
                    img{
                        height: 300px !important;
                        object-fit: cover;
                    }
                }
                #visit-content-image-caption{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    @include align-center;
                    margin-top: 15px;
                    >div{
                        &:first-child{
                            width: 30px;
                            img{
                                position: relative;
                                top: -2px;
                            }
                        }
                        &:last-child{
                            padding-left: 5px;
                            p{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

#visit-widgets-container{
    padding: 80px 0;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            width: 48%;
            &:nth-child(2n){
                margin-left: 4%;
            }
            .image-wrapper{
                height: 300px;
                margin-bottom: 20px;
                img{
                    height: 300px !important;
                    object-fit: cover;
                }
            }
        }
    }
}

#single-visit-header-container{
    background: $maroon;
    padding: 80px 0;
    margin-top: 130px;
    h1, h2, h3, h4, h5, h6, p{
        color: $white;
    }
    p{
        margin-bottom: 0;
    }
}

#single-visit-image-container{
    height: 640px;
    img{
        object-fit: cover;
    }
    #single-visit-image-caption-container{
        width: 100%;
        padding: 0 50px 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        a{
            cursor: pointer;
        }
    }
}

#single-visit-alert-bar-container{
    background: $yellow;
    .container{
        padding: 20px 0;
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            >div{
                &:first-child{
                    width: 30px;
                    .icon-alert{
                        color: $maroon;
                        position: relative;
                        top: 1px;
                        font-size: 24px;
                        &:before{
                            content: '\e902';
                        }
                    }
                }
                &:last-child{
                    width: calc(100% - 30px);
                    padding-left: 5px;
                }
            }
        }
    }
    a{
        color: $brownDark;
        text-decoration: underline;
        p{
            margin-bottom: 0;
        }
    }
}

#single-visit-content-container{
    padding: 105px 0 40px;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            &:first-child{
                width: calc(100% - 310px);
                padding-right: 60px;
            }
            &:last-child{
                width: 310px;
                >div{
                    background: $warmGray;
                    padding: 25px;
                    p{
                        font-size: 16px;
                        margin-bottom: 0;;
                        &.title{
                            margin-bottom: 0;
                            font-weight: bold;
                        }
                    }
                    #single-visit-address{
                        #map{
                            margin: 15px 0;
                        }
                    }
                    #single-visit-hours{
                        border-bottom: 1px solid $gray;
                        padding-bottom: 15px;
                    }
                    #single-visit-map{
                        padding: 15px 0;
                        border-bottom: 1px solid $gray;
                        a{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            color: $brownDark;
                            font-size: 16px;
                            font-weight: bold;
                            text-decoration: none;
                            >div{
                                &:first-child{
                                    width: 24px;
                                    .icon-download{
                                        &:before{
                                            content: '\e901'
                                        }
                                    }
                                }
                                &:last-child{
                                    width: calc(100% - 24px);
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                    #single-visit-features{
                        padding-top: 15px;
                        p{
                            margin-bottom: 10px;
                        }
                        >div{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            @include align-center;
                            &:not(:last-of-type){
                                margin-bottom: 10px;
                            }
                            >div{
                                &:first-child{
                                    width: 30px;
                                    span{
                                        position: relative;
                                        top: 2px;
                                    }
                                }
                                &:last-child{
                                    width: calc(100% - 30px);
                                    padding-left: 5px;
                                    p{
                                        margin-bottom: 0;;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#single-visit-accordions-container{
    padding: 40px 0;
    .accordion-item{
        margin-bottom: 10px;
        .accordion-header{
            margin-bottom: 0;
            button{
                color: $brownDark;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @include align-center;
                margin-bottom: 5px;
                border: none;
                background: transparent;
                text-align: left;
                width: 100%;
                padding: 0;
                &[aria-expanded="true"]{
                    >div{
                        &:first-child{
                            .icon-plus{
                                opacity: 0.7;
                                @include transition(all, 300ms, ease-in-out);
                            }
                        }
                    }
                }
                >div{
                    &:first-child{
                        width: 30px;
                        .icon-plus{
                            font-size: 24px;
                            position: relative;
                            top: -5px;
                            opacity: 1;
                            @include transition(all, 300ms, ease-in-out);
                            &:before{
                                content: '\e909';
                            }
                        }
                    }
                    &:last-child{
                        width: calc(100% - 30px);
                        padding-left: 5px;
                        h4{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .accordion-body{
            p:last-child{
                margin-bottom: 10px;
            }
        }
    }
}

#single-visit-gallery-container{
    background-color: $warmGray;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            width: 50%;
            position: relative;
            &:first-child{
                padding: 200px 80px 200px 20px;
                >div{
                    width: 100%;
                    float: right;
                    max-width: 550px;
                }
            }
            &:last-child{
                span{
                    img{
                        object-fit: cover;
                    }
                }
                .gallery-background-image{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1000;
                }
                #single-visit-gallery-caption-container{
                    width: 100%;
                    padding: 0 50px 30px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    a{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

#single-visit-closest{
    padding: 80px 0;
    width: 100%;
    max-width: 640px;
    h2{
        text-align: center;
        margin-bottom: 50px;
    }
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            width: 48%;
            background: $white;
            border: 1px solid $warmGray;
            &:nth-of-type(2n){
                margin-left: 4%;
            }
            a{
                text-decoration: none;
                h2, p{
                    margin-bottom: 0;
                    color: $brownDark;
                }
                h2{
                    color: $brownLight;
                    margin-bottom: 5px;
                    font-size: 20px;
                    text-align: left;
                }
                p{
                    font-size: 16px;
                }
            }
            .image-wrapper{
                position: relative;
                width: 100%;
                height: 220px;
                display: inline-block;
                img{
                    object-fit: cover;
                    height: 220px !important;
                }
            }
            .visit-content{
                padding: 24px;
            }
        }
    }
}

#single-visit-news{
    padding: 80px 0;
    h2{
        text-align: center;
        margin-bottom: 50px;
    }
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include align-center;
        border: 1px solid $warmGray;
        >div{
            &:first-child{
                width: 45%;
                position: relative;
                >span{
                    display: flex !important;
                }
                a{
                    display: inline-block;
                    width: 100%;
                    img{
                        object-fit: cover !important;
                    }
                }
            }
            &:last-child{
                width: 55%;
                padding: 0 50px;
                h4{
                    margin-bottom: 10px;
                }
                p{
                    margin-bottom: 20px;
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 1380px){
    #visit-list-container{
        padding: 30px 20px 40px;
    }
}

@media screen and (max-width: 1240px){
    #visit-header-container{
        margin-top: 0;
    }
    #single-visit-header-container{
        margin-top: 0;
    }
}

@media screen and (max-width: 1120px){
    #visit-header-container{
        padding: 80px 20px;
    }
    #visit-category-container{
        max-width: calc(100% - 40px);
    }
    #visit-content-container{
        padding: 0 20px 60px;
    }
    #visit-widgets-container{
        padding: 80px 20px;
    }
    #single-visit-header-container{
        padding: 80px 20px;
    }
    #single-visit-image-container{
        #single-visit-image-caption-container{
            padding: 0 20px 50px;
        }
    }
    #single-visit-alert-bar-container{
        .container{
            padding: 20px;
        }
    }
    #single-visit-content-container{
        padding: 105px 20px 40px;
    }
    #single-visit-accordions-container{
        padding: 40px 20px;
    }
    #single-visit-gallery-container{
        >div{
            >div{
                &:first-child{
                    padding: 200px 40px 200px 20px;
                }
            }
        }
    }
    #single-visit-content{
        padding: 80px 20px;
    }
    #single-visit-news{
        padding: 80px 20px;
    }
}

@media screen and (max-width: 1000px){
    #single-visit-gallery-container{
        >div{
            flex-direction: column-reverse;
            >div{
                width: 100%;
                &:first-child{
                    padding: 70px 20px;
                    >div{
                        float: none;
                        max-width: 100%;
                    }
                }
                &:last-child{
                    height: 400px;
                    #single-visit-gallery-caption-container{
                        padding: 0 20px 20px;
                    }
                }
            }
        }
    }
    #single-visit-news{
        >div{
            >div{
                &:first-child, &:last-child{
                    width: 100%;
                }
                &:first-child{
                    span{
                        width: 100% !important;
                    }
                    img{
                        width: 100%;
                        object-fit: cover !important;
                    }
                }
                &:last-child{
                    padding: 50px 20px;
                }
            }
        }
    }
}

@media screen and (min-width: 901px){
    #visit-list-container{
        >div{
            >div{
                width: 24%;
                &:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2){
                    margin-left: 1%;
                }
            }
        }
    }
}

@media screen and (min-width: 651px) and (max-width: 900px){
    #visit-list-container{
        >div{
            >div{
                width: 31%;
                &:nth-of-type(3n-1){
                    margin-left: 3.333%;
                    margin-right: 3.333%;
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    #visit-header-container{
        padding: 40px 20px;
    }
    #visit-category-container{
        padding: 30px 0 20px;
        overflow-x: scroll;
        >div{
            width: 900px;
        }
    }
    #visit-content-container{
        padding: 0 20px 10px;
        >div{
            flex-direction: column-reverse;
            >div{
                width: 100%;
                &:first-child{
                    padding: 30px 0 0;
                }
                &:last-child{
                    .image-wrapper{
                        height: 220px;
                        img{
                            height: 220px !important;
                        }
                    }
                }
            }
        }
    }
    #visit-widgets-container{
        padding: 60px 20px;
        >div{
            >div{
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
                &:nth-of-type(2n){
                    margin-left: auto;
                    margin-top: 50px;
                }
                .image-wrapper{
                    height: 220px;
                    img{
                        height: 220px !important;
                    }
                }
            }
        }
    }
    #single-visit-header-container{
        padding: 50px 20px;
    }
    #single-visit-image-container{
        height: 300px;
        #single-visit-image-caption-container{
            padding: 0 20px 20px;
        }
    }
    #single-visit-content-container{
        padding: 60px 20px 30px;
        >div{
            flex-direction: column-reverse;
            >div{
                &:first-child{
                    width: 100%;
                    padding: 40px 0 0;
                }
                &:last-child{
                    width: 100%;
                    >div{
                        max-width: 350px;
                        margin: 0 auto;
                        display: block;
                    }
                }
            }
        }
    }
    #single-visit-gallery-container{
        >div{
            >div{
                &:first-child{
                    padding: 60px 20px;
                }
                &:last-child{
                    height: 250px;
                }
            }
        }
    }
    #single-visit-closest{
        padding: 60px 20px;
        >div{
            >div{
                width: 100%;
                &:nth-child(2){
                    margin: 30px 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 650px){
    #visit-list-container{
        >div{
            >div{
                width: 100%;
                max-width: 300px;
                margin: 0 auto 30px;
            }
        }
    }
}