#content{
	#error-container{
		margin-top: 130px;
		padding: 100px 20px;
		text-align: center;
		img{
			display: block;
			margin: 0 auto;
			float: none;
		}
	}
	h1{
		font-family: $secondaryFont;
		font-size: 60px;
		line-height: 1.1;
		margin-bottom: 20px;
		&.news-title{
			font-size: 40px;
			line-height: 1.3;
		}
	}
	h2{
		font-family: $secondaryFont;
		font-size: 40px;
		line-height: 1.3;
		margin-bottom: 20px;
		&.preserve-title{
			font-size: 24px;
			line-height: 1.5;
		}
		&.accordion-title{
			font-size: 24px;
			line-height: 1.5;
		}
	}
	h3{
		font-family: $secondaryFont;
		font-size: 30px;
		line-height: 1.4;
		margin-bottom: 20px;
	}
	h4{
		font-family: $secondaryFont;
		font-size: 24px;
		line-height: 1.5;
		margin-bottom: 20px;
	}
	p{
		line-height: 1.667;
		margin-bottom: 30px;
	}
	ul{
		padding: 0;
		margin: 20px 0;
		list-style: none;
		li{
			padding-left: 25px;
			margin-bottom: 10px;
			position: relative;
			&:before{
				position: absolute;
				content: '';
				top: 3px;
				left: 0px;
				background: $brownLight;
				width: 20px;
				height: 20px;
				display: inline-block;
				border-radius: 50%;
			}
			&:after{
				position: absolute;
				content: '\e908';
				top: 8px;
				left: 5px;
				color: $white;
				font-family: $iconFont;
				font-size: 7px;
			}
		}
	}
	ol{
		list-style-position: outside;
		margin: 20px 0;
		padding: 0;
		list-style: none;
		counter-reset: section;
		>li{
			margin-bottom: 10px;
			position: relative;
			padding-left: 25px;
			&:before{
				position: absolute;
				left: 0px;
				top: 3px;
				counter-increment: section;
				content: counter(section) "";
				color: $white;
				z-index: 100;
				font-size: 12px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				width: 20px;
				height: 20px;
				border-radius: 50%;
			}
			&:after{
				position: absolute;
				content: '';
				top: 3px;
				left: 0px;
				background: $brownLight;
				width: 20px;
				height: 20px;
				display: inline-block;
				border-radius: 50%;
			}
		}
	}
	a{
		&:not(.button):not(.custom-link){
			color: $brownLight;
			text-decoration: underline;
		}
		&.button{
			font-family: $secondaryFont;
			text-transform: lowercase;
			&.red{
				@include red-button;
			}
			&.yellow{
				@include yellow-button;
			}
			&.red-border{
				@include red-border-button;
			}
			&.arrow{
				@include red-arrow-button;
			}
			&.blue-arrow{
				@include blue-arrow-button;
			}
			&.blue{
				@include blue-button;
			}
			&.light-blue{
				@include light-blue-button;
			}
			&.blue-border{
				@include blue-border-button;
			}
		}
	}
	button{
		&.button{
			font-family: $secondaryFont;
			text-transform: lowercase;
			&.red{
				@include red-button;
				border: none;
			}
			&.yellow{
				@include yellow-button;
				border: none;
			}
			&.red-border{
				@include red-border-button;
			}
			&.arrow{
				@include red-arrow-button;
				border: none;
			}
		}
	}
	blockquote{
		padding: 0;
		margin: 40px auto;
		border: none;
		border-top: 1px solid $gray;
		border-bottom: 1px solid $gray;
		padding: 40px 0;
		h1, h2, h3, h4, h5, h6, p{
			color: $maroon;
			margin: 0;
			font-size: 24px;
			line-height: 1.5;
			font-family: $secondaryFont;
		}
	}
}

@media screen and (max-width: 1240px){
	#content{
		#error-container{
			margin-top: 0;
		}
	}
}

@media screen and (max-width: 767px) {
	#content{
		h1{
			font-size: 45px;
			&.news-title{
				font-size: 30px;
			}
		}
		h2{
			font-size: 30px;
			&.preserve-title{
				font-size: 20px;
			}
			&.accordion-title{
				font-size: 20px;
			}
		}
		h3{
			font-size: 25px;
		}
		h4{
			font-size: 20px;
		}
		p{
			font-size: 16px;
		}
		ul{
			li{
				font-size: 16px;
				&:before{
					top: 1px;
				}
				&:after{
					top: 5px;
				}
			}
		}
		ol{
			li{
				font-size: 16px;
				&:before{
					top: 1px;
				}
				&:after{
					top: 1px;
				}
			}
		}
		a{
			&.button{
				&.yellow, &.red, &.blue, &.light-blue, &.blue-border, &.red-border, &.arrow{
					width: 100%;
					max-width: 500px;
				}
				&.arrow{
					display: inline-block;
				}
				&.arrow.center{
					text-align: center;
				}
			}
		}
		blockquote{
			h1, h2, h3, h4, h5, h6, p{
				font-size: 20px;
			}
		}
	}
}