#plants-header-container{
    padding: 80px 20px;
    h1, h2, h3, h4, h5, h6{
        color: $blueDark;
    }
}

#plants-filter-container{
    padding: 0 15px;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            width: 31%;
            &:nth-of-type(3n-1){
                margin: 0 3.333%;
            }
            label{
                font-family: $secondaryFont;
                font-size: 16px;
            }
            #search-wrapper{
                position: relative;
                input[type="text"]{
                    width: 100%;
                    height: 50px;
                    border: 1px solid $brownDark;
                    padding: 0 15px;
                    font-size: 16px;
                    color: $brownDark;
                    &::-webkit-input-placeholder {
                        color: $brownDark;
                    }
                    
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $brownDark;
                        opacity: 1;
                    }
                    
                    &::-moz-placeholder {  /* Firefox 19+ */
                       color: $brownDark;
                       opacity: 1;
                    }
                    
                    &:-ms-input-placeholder {  
                        color: $brownDark;
                    }
                }
                .icon-search{
                    position: absolute;
                    top: 16px;
                    right: 15px;
                    font-size: 16px;
                    &:before{
                        content: '\e90b';
                    }
                }
            }
            .select-wrapper{
                width: 100%;
                position: relative;
                height: 50px;
                border-radius: 0px;
                border: 1px solid $brownDark;
                &:after{
                    position: absolute;
                    content: '\e900';
                    display: inline-block;
                    @include rotate(90deg);
                    top: 15px;
                    right: 15px;
                    font-family: $iconFont;
                    color: $brownDark;
                    font-size: 12px;
                }
                select{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 47px;
                    border-radius: 0px;
                    border: none;
                    padding: 0 15px;
                    font-size: 16px;
                }
            }
        }
    }
}

#plants-container{
    padding: 80px 0;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            width: 25%;
            padding: 0 10px;
            margin-bottom: 50px;
            a{
                display: inline-block;
                width: 100%;
                text-decoration: none !important;
                .image-wrapper{
                    position: relative;
                    width: 100%;
                    height: 200px;
                    margin-bottom: 15px;
                    img{
                        object-fit: cover;
                    }
                }
                p{
                    color: $brownDark;
                    margin: 0;
                    &.title{
                        color: $blueDark;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

#single-plants-header-container{
    padding: 80px 0;
    a{
        text-decoration: none !important;
        border-bottom: 2px solid $brownDark;
    }
}

#single-plants-container{
    padding: 0 0 100px;
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            &:first-child{
                width: 40%;
                .image-wrapper{
                    position: relative;
                    img{
                        object-fit: cover;
                        width: 100%;
                    }
                    .plant-image-caption{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 20px;
                        img{
                            height: 24px;
                        }
                    }
                }
            }
            &:last-child{
                width: 60%;
                padding-left: 130px;
                .scientific-name{
                    margin-bottom: 20px;
                    p{
                        font-size: 24px;
                        margin-bottom: 0;
                    }
                }
                h2{
                    color: $blueDark;
                }
                #single-plants-tags{
                    >div{
                        display: inline-block;
                        margin-right: 10px;
                        a{
                            background: $blueLight;
                            display: inline-block;
                            padding: 10px 15px;
                            text-decoration: none !important;
                            color: $blueDark;
                            font-size: 16px;
                            &:hover{
                                .icon-arrow{
                                    right: -5px;
                                    @include transition(all, 300ms, ease-in-out);
                                }
                            }
                            .icon-arrow{
                                font-size: 12px;
                                position: relative;
                                margin-left: 5px;
                                right: 0;
                                @include transition(all, 300ms, ease-in-out);
                                &:before{
                                    content: '\e900';
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1120px){
    #plants-header-container{
        padding: 60px 20px;
    }
    #plants-container{
        padding: 80px 5px;
        >div{
            >div{
                margin-bottom: 30px;
            }
        }
    }
    #single-plants-header-container{
        padding: 80px 20px;
    }
    #single-plants-container{
        padding: 0 20px 100px;
        >div{
            >div{
                &:last-child{
                    padding-left: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    #plants-container{
        >div{
            >div{
                width: 33.3333%;
            }
        }
    }
}

@media screen and (max-width: 850px){
    #plants-filter-container{
        >div{
            >div{
                width: 100%;
                &:nth-of-type(3n-1){
                    margin: 20px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    #plants-header-container{
        padding: 60px 20px 10px;
    }
    #plants-container{
        >div{
            >div{
                width: 50%;
                a{
                    .image-wrapper{
                        height: 150px;
                    }
                }
            }
        }
    }
    #single-plants-header-container{
        padding: 60px 20px;
    }
    #single-plants-container{
        padding: 0 20px 80px;
        >div{
            >div{
                &:first-child, &:last-child{
                    width: 100%;
                }
                &:first-child{
                    .image-wrapper{
                        width: 100%;
                        >span{
                            width: 100% !important;
                        }
                    }
                }
                &:last-child{
                    padding: 30px 0 0;
                    .scientific-name{
                        p{
                            font-size: 20px;
                        }
                    }
                    #single-plants-tags{
                        >div{
                            display: block;
                            &:not(:last-of-type){
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}