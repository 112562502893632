#stoneleigh-top-footer-container{
    background: $blueDark;
    padding: 80px 0;
    &:before{
        position: absolute;
        content: '';
        background-image: url('../images/footer-background.svg');
        background-size: cover;
        background-position: center bottom;
        left: 0;
        bottom: 0;
        width: 270px;
        height: 370px;
    }
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            >div{
                &:first-child{
                    width: calc(100% - 310px);
                    a{
                        display: inline-block;
                    }
                    #stoneleigh-locations{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        >div{
                            &:first-child{
                                width: 220px;
                            }
                            &:last-child{
                                width: 260px;
                                padding-left: 20px;
                            }
                            .address{
                                margin-top: 20px;
                                p{
                                    font-size: 16px;
                                }
                                a{
                                    display: none;
                                }
                            }
                            .footer-social-container{
                                margin-top: 20px;
                                a{
                                    display: inline-block;
                                    vertical-align: middle;
                                    color: $white;
                                    text-decoration: none;
                                    font-size: 30px;
                                    opacity: 1;
                                    &:hover{
                                        opacity: 0.7;
                                    }
                                    &:not(:last-of-type){
                                        margin-right: 15px;
                                    }
                                    span{
                                        &.icon-facebook{
                                            &:before{
                                                content: '\e906';
                                            }
                                        }
                                        &.icon-instagram{
                                            &:before{
                                                content: '\e905';
                                            }
                                        }
                                        &.icon-linkedin{
                                            &:before{
                                                content: '\e904';
                                            }
                                        }
                                        &.icon-tiktok{
                                            &:before{
                                                content: '\e903';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    >div{
                        margin-top: 20px;
                        &#footer-disclaimer{
                            p{
                                font-size: 16px;
                            }
                        }
                        &#footer-logos{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            @include align-center;
                            >div{
                                &:not(:last-of-type){
                                    margin-right: 15px;
                                }
                                img{
                                    max-width: 190px;
                                    max-height: 70px;
                                    object-fit: cover;
                                }
                            }
                        }
                        p{
                            color: $white;
                            line-height: 1.667;
                        }
                    }
                };
                &:last-child{
                    width: 310px;
                    p{
                        color: $white;
                        line-height: 1.667;
                    }
                    a.button{
                        margin-top: 20px;
                        &.red{
                            @include red-button;
                        }
                        &.yellow{
                            @include yellow-button;
                        }
                        &.red-border{
                            @include red-border-button;
                        }
                        &.arrow{
                            @include red-arrow-button;
                        }
                        &.blue-arrow{
                            @include blue-arrow-button;
                        }
                        &.blue{
                            @include blue-button;
                        }
                        &.light-blue{
                            @include light-blue-button;
                        }
                        &.blue-border{
                            @include blue-border-button;
                        }
                    }
                }
            }
        }
    }
}

#stoneleigh-bottom-footer-container{
    padding: 25px 0;
    background: $offwhite;
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include align-center;
            >div{
                &:first-child, &:last-child{
                    width: 25%;
                    position: absolute;
                    top: 3px;
                    p{
                        font-size: 16px;
                    }
                }
                &:last-child{
                    right: 0;
                    top: -1px;
                    text-align: right;
                    a{
                        font-size: 16px;
                        color: $brownLight;
                        text-decoration: none;
                        &:hover{
                            color: $maroon;
                        }
                        .icon-cacpro{
                            font-size: 18px;
                            margin-left: 5px;
                            position: relative;
                            top: 2px;
                            &:before{
                                content: '\e90e';
                            }
                        }
                    }
                }
                &:nth-child(2){
                    width: 100%;
                    text-align: center;
                    ul{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        >li{
                            display: inline-block;
                            &:not(:last-of-type){
                                margin-right: 20px;
                            }
                            a{
                                font-size: 16px;
                                color: $brownLight;
                                text-decoration: none;
                                &:hover{
                                    color: $maroon;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1380px){
    #stoneleigh-bottom-footer-container{
        padding: 25px 20px;
    }
}

@media screen and (max-width: 1120px){
    #stoneleigh-top-footer-container{
        padding: 80px 20px;
    }
}

@media screen and (max-width: 1000px){
    #stoneleigh-top-footer-container{
        >div{
            >div{
                >div{
                    &:first-child, &:last-child{
                        width: 100%;
                        text-align: center;
                        #stoneleigh-locations{
                            justify-content: center;
                        }
                        #footer-logos{
                            justify-content: center;
                        }
                    }
                    &:last-child{
                        padding: 50px 0 0;
                    }
                }
            }
        }
    }
    #stoneleigh-bottom-footer-container{
        >div{
            >div{
                >div{
                    &:nth-child(1){
                        order: 1;
                        width: 100%;
                        position: relative;
                        text-align: center;
                        top: 0;
                        padding: 10px 0;
                    }
                    &:nth-child(2){
                        order: 0;
                        width: 100%;
                        text-align: center;
                    }
                    &:nth-child(3){
                        width: 100%;
                        position: relative;
                        text-align: center;
                        order: 2;
                        top: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    #stoneleigh-top-footer-container{
        padding: 50px 20px;
        >div{
            >div{
                >div{
                    &:first-child{
                        >div{
                            &#address{
                                display: none;
                            }
                            &#mobile-address{
                                display: block;
                            }
                            &#stoneleigh-locations{
                                >div{
                                    &:first-child, &:last-child{
                                        width: 100%;
                                    }
                                    &:last-child{
                                        margin-top: 30px;
                                    }
                                }
                            }
                            &#footer-disclaimer{
                                p{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    &:last-child{
                        text-align: left;
                        .button{
                            width: 100%;
                            max-width: 500px;
                        }
                    }
                }
            }
        }
    }
}