#staff-header-container{
    background: $offwhite;
    padding: 80px 0;
    margin-top: 130px;
}

#staff-menu-container{
    padding: 20px 0 40px;
    border-top: 1px solid $warmGray;
    >div{
        >div{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            >div{
                &:not(:last-of-type){
                    margin-right: 20px;
                }
                a{
                    color: $brownLight;
                    text-decoration: none;
                    text-transform: lowercase;
                    position: relative;
                    &.active, &:hover{
                        &:before{
                            -webkit-transform: scale3d(1, 1, 1);
                            transform: scale3d(1, 1, 1);
                            -webkit-transition-delay: 0s;
                            transition-delay: 0s;
                        }
                    }
                    &:before{
                        background: $brownDark;
                        -webkit-transition-delay: 0.4s;
                        transition-delay: 0.4s;
                        content: '';
                        position: absolute;
                        top: calc(100% + 5px);
                        left: 0;
                        width: 100%;
                        height: 2px;
                        -webkit-transform: scale3d(0, 1, 1);
                        transform: scale3d(0, 1, 1);
                        -webkit-transform-origin: center left;
                        transform-origin: center left;
                        -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                        transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                    }
                    &:hover{
                        color: $brownDark;
                    }
                }
            }
        }
    }
}

#staff-image-container{
    height: 640px;
    img{
        object-fit: cover;
    }
    #staff-image-caption-container{
        width: 100%;
        padding: 0 50px 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        a{
            cursor: pointer;
        }
    }
}

#staff-container{
    padding: 80px 0 40px;
    h2{
        margin-bottom: 40px;
    }
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        >div{
            a{
                text-decoration: none;
                display: inline-block;
                color: $brownDark;
                text-decoration: none;
                margin-bottom: 40px;
                .image-wrapper{
                    width: 200px;
                    height: 200px;
                    margin-bottom: 15px;;
                    img{
                        object-fit: cover;
                    }
                }
                h4{
                    margin-bottom: 10px;
                }
                p{
                    font-size: 16px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

#staff-single-column-container{
    padding: 50px 0 80px;
}

#staff-two-column-container{
    padding: 50px 0 80px;
    >div{
        &:last-child{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 30px;
            >div{
                width: 48%;
                &:last-child{
                    margin-left: 4%;
                }
            }
        }
    }
}

#single-staff-header-container{
    background: $warmGray;
    height: 240px;
    margin-top: 130px;
}

#single-staff-container{
    #staff-title-container{
        text-align: center;
        #staff-image{
            width: 100%;
            max-width: 310px;
            height: 310px;
            display: block;
            margin: 0 auto -130px;
            position: relative;
            top: -160px;
            img{
                object-fit: cover;
            }
        }
    }
    #staff-content-container{
        padding: 0 0 50px;
    }
}

@media screen and (max-width: 1240px){
    #staff-header-container, #single-staff-header-container{
        margin-top: 0;
    }
}

@media screen and (max-width: 1120px){
    #staff-menu-container{
        max-width: calc(100% - 40px);
    }
    #staff-header-container{
        padding: 80px 20px;
    }
    #staff-image-container{
        #staff-image-caption-container{
            padding: 0 20px 50px;
        }
    }
    #staff-container{
        padding: 80px 20px 40px;
    }
    #staff-single-column-container{
        padding: 50px 20px 80px;
    }
    #staff-two-column-container{
        padding: 50px 20px 80px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1100px){
    #staff-container{
        >div{
            >div{
                width: 31%;
                &:nth-of-type(3n-1){
                    margin-left: 3.333%;
                    margin-right: 3.333%;
                }
            }
        }
    }
}

@media screen and (min-width: 1101px){
    #staff-container{
        >div{
            >div{
                width: 18%;
                &:nth-of-type(5n), &:nth-of-type(5n-1), &:nth-of-type(5n-2), &:nth-of-type(5n-3){
                    margin-left: 2.5%;
                }
            }
        }
    }
}

@media screen and (max-width: 900px){
    #single-staff-container{
        padding: 0 20px;
    }
}

@media screen and (max-width: 767px){
    #staff-header-container{
        padding: 40px 20px;
    }
    #staff-menu-container{
        overflow-x: scroll;
        padding: 5px 0 20px;
        >div{
            width: 800px;
        }
    }
    #staff-image-container{
        height: 300px;
        #staff-image-caption-container{
            padding: 0 20px 20px;
        }
    }
    #staff-container{
        padding: 60px 20px 40px;
        >div{
            >div{
                width: 100%;
                text-align: center;
                a{
                    .image-wrapper{
                        margin: 0 auto 15px;
                    }
                }
            }
        }
    }
    #staff-two-column-container{
        >div{
            &:last-child{
                >div{
                    width: 100%;
                    &:last-child{
                        margin-left: 0;
                    }
                }
            }
        }
    }
}