@font-face {
	font-family: 'icomoon';
	src:  url('..//fonts/icomoon.woff2?dgv5f6') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }

@import url("https://use.typekit.net/dxt8ohh.css");

$primaryFont: 'adelle-sans';
$secondaryFont: 'lexia';
$iconFont: 'icomoon';

$white: #fff;
$maroon: #820f11;
$maroonHover: #6b0c0e;
$yellow: #ffcc41;
$yellowHover: #e7b11e;
$brownDark: #41382e;
$brownLight: #6e625a;
$green: #036a38;
$greenLight: #68a527;
$blueDark: #285162;
$blueLight: #c3e7e8;
$warmGray: #ededed;
$offwhite: #fcfcfc;
$gray: #d9d9d9;


/*! purgecss start ignore */
@mixin transition($property: all, $duration: 0.4s, $easing: ease-in-out) {
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
  -ms-transition: $property $duration $easing;
  -o-transition: $property $duration $easing;
  transition: $property $duration $easing;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

 @mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-moz-transform: rotate($degrees);
	-ms-transform: rotate($degrees);
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}

@mixin prefixer($property, $value, $prefixes: webkit) {
    @each $prefix in $prefixes {
        #{"-" + $prefix + "-" + $property}: $value;
    }
    #{$property}: $value;
}

@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin align-center{
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center;
	align-content: center;
}

@mixin button{
	display: inline-block;
	text-align: center;
	border: none;
	font-family: $secondaryFont;
	text-decoration: none;
}

@mixin blue-button{
	@include button;
	background: $blueDark;
	color: $white;
	min-width: 140px;
	padding: 12px 20px;
	&:hover{
		background: $blueLight;
		color: $blueDark;
	}
}

@mixin light-blue-button{
	@include button;
	background: $blueLight;
	color: $blueDark;
	min-width: 140px;
	padding: 12px 20px;
	&:hover{
		background: $blueDark;
		color: $white;
	}
}

@mixin blue-border-button{
	@include button;
	background: transparent;
	border: 1px solid $blueDark;
	color: $blueDark;
	min-width: 140px;
	padding: 12px 20px;
	&:hover{
		background: $blueDark;
		border: 1px solid $blueDark;
		color: $white;
	}
}

@mixin white-border-button{
	@include button;
	background: transparent;
	border: 1px solid $white;
	color: $white;
	min-width: 140px;
	padding: 12px 20px;
	font-family: $secondaryFont;
	&:hover{
		background: $white;
		color: $blueDark;
	}
}

@mixin red-button{
	@include button;
	background: $maroon;
	color: $white;
	min-width: 140px;
	padding: 12px 20px;
	&:hover{
		background: $maroonHover;
	}
}

@mixin red-border-button{
	@include button;
	background: transparent;
	border: 1px solid $maroon;
	color: $maroon;
	min-width: 140px;
	padding: 12px 20px;
	&:hover{
		background: $maroonHover;
		border: 1px solid $maroonHover;
		color: $white;
	}
}

@mixin yellow-button{
	@include button;
	background: $yellow;
	color: $brownDark;
	min-width: 140px;
	padding: 12px 20px;
	&:hover{
		background: $yellowHover;
	}
}

@mixin red-arrow-button{
	text-decoration: none;
	font-family: $secondaryFont;
	font-size: 18px;
	color: $maroon;
	&:hover{
		color: $maroonHover;
		.icon-arrow{
			right: -5px;
			@include transition(all, 300ms, ease-in-out);
		}
	}
	.icon-arrow{
		position: relative;
		font-size: 12px;
		top: 0px;
		margin-left: 5px;
		right: 0;
		@include transition(all, 300ms, ease-in-out);
		&:before{
			content: '\e900';
		}
	}
}

@mixin blue-arrow-button{
	text-decoration: none;
	font-family: $secondaryFont;
	font-size: 18px;
	color: $blueDark;
	&:hover{
		.icon-arrow{
			right: -5px;
			@include transition(all, 300ms, ease-in-out);
		}
	}
	.icon-arrow{
		position: relative;
		font-size: 12px;
		top: 0px;
		margin-left: 5px;
		right: 0;
		@include transition(all, 300ms, ease-in-out);
		&:before{
			content: '\e900';
		}
	}
}

/*! purgecss end ignore */

/*
	Body & HTML setup
	
*/

#full-height{
	display: none;
}

body, html {
	height:100%;	
}

body {
    font-family: $primaryFont;
	font-size: 18px;
	color: $brownDark;
	background: $offwhite;
	&.nav-menu-open{
		position: fixed;
		overflow: hidden;
		left: 0px;
		right: 0px;		
	}	
}

.container {
    position: relative;
	margin-left: auto;
	margin-right: auto;
	*zoom: 1;
	max-width: 1080px;
	min-width: 0px;
	width: 100%;
	padding: 0;	
	&.large-container{
		max-width: 860px;
	}
	&.xsmall-container{
		max-width: 770px;
	}
	&.xsmall-container-alt{
		max-width: 830px;
	}
	&.xlarge-container{
		max-width: 1380px;
	}
}

.container-fluid{
    position: relative;
	margin-left: auto;
	margin-right: auto;
	*zoom: 1;
	width: 100%;
	max-width:2000px;
	min-width: 320px;	
	padding: 0;		
}

.wrap {
	position: relative;
	margin: 0 auto -90px; /* bottom is negative value of .push height */
	min-height: 100%;
	height: auto !important;
	height: 100%;
	max-width: 2000px;
	background: $offwhite;
}
.push {
	height: 90px;
	background: transparent;
} /* footer height plus padding */

.padding
{
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.nomargin{
	margin-left: 0px !important;
	margin-right: 0px !important;
}

::-moz-selection { /* Code for Firefox */
    color: white;
    background: $maroon;
}

::selection {
    color: white; 
    background: $maroon;
}

:focus
{
	//outline: 0px none transparent;
}

::-moz-focus-inner {
    border: 0;
    padding: 0;
}

::-webkit-input-placeholder {
	color: rgba(23, 36, 42, 0.5);
}

:-moz-placeholder { /* Firefox 18- */
	color: rgba(23, 36, 42, 0.5);
   	opacity: 1;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: rgba(23, 36, 42, 0.5);
   opacity: 1;
}

:-ms-input-placeholder {  
	color: rgba(23, 36, 42, 0.5);
}

h1, h2, h3, h4, h5, h6, p{
     margin-top: 0px;
     margin-bottom: 0px;
}
input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="submit"], textarea, select{
	-webkit-appearance: none;
	border-radius: 0px;
}

#preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

a, input[type="submit"], button{
	@include transition(all, 300ms, ease-in-out);	
	&:hover{
		@include transition(all, 300ms, ease-in-out);
	}
}
.wp-caption{
	max-width: 100% !important;
	margin-bottom: 20px;
	&.alignright{
		float: right; 
		margin: 0 0 1em 1em;
	}
	&.alignleft{
		float: left; 
		margin: 0 1em 1em 0;
	}
	&.aligncenter{
		display: block; 
		margin-left: auto; 
		margin-right: auto;
	}
	img{
		height: auto !important;
		max-width: 100%;
		margin-bottom: 10px;
	}
	.wp-caption-text{
		font-size: 14px;
	}
}
img{
	max-width: 100%;
	&.alignright{
		float: right; 
		margin: 0 0 1em 1em;
	}
	&.alignleft{
		float: left; 
		margin: 0 1em 1em 0;
	}
	&.aligncenter{
		display: block; 
		margin-left: auto; 
		margin-right: auto;
	}
}
.alignright{ 
	float: right; 
}
.alignleft{
	float: left; 
}
.aligncenter{ 
	display: block;
	margin-left: auto; 
	margin-right: auto; 
}

img{
	max-width: 100%;
	&:not([src]) {
    	visibility: hidden;
	}
	&.alignright{
		float: right; 
		margin: 0 0 1em 1em;
	}
	&.alignleft{
		float: left; 
		margin: 0 1em 1em 0;
	}
	&.aligncenter{
		display: block; 
		margin-left: auto; 
		margin-right: auto;
	}
}
.alignright{ 
	float: right; 
}
.alignleft{
	float: left; 
}
.aligncenter{ 
	display: block;
	margin-left: auto; 
	margin-right: auto; 
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

@media screen and (max-width: 767px){
	img{
		&.alignright, &.alignleft{
			float: none;
			display: block;
			margin: 0 auto 30px;
		}
	}
	.alignright, .alignleft{
		float: none;
		margin: 0 auto 30px;
	}
}

[class^="icon-"], [class*=" icon-"] {
	font-family: $iconFont !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.animsition-loading{
	border: none;
    -webkit-animation-iteration-count: none;
    animation-iteration-count: none;
    -webkit-animation-timing-function: none;
    animation-timing-function: none;
    -webkit-animation-duration: 0s;
    animation-duration: 0s;
    -webkit-animation-name: none;
    animation-name: none;	
    width: 60px;
}

@media screen and (max-width: 767px){
    .animatedParent .animated {
        @include prefixer(transition-property, none !important);
        @include prefixer(animation, none !important);
        @include transform(none !important);
        opacity: 1
    }	
}

@media screen and (max-width: 767px){
	#lightboxContainer{
		.react-transform-component{
			.imageModal{
				max-width: calc(90% - 40px);
				left: 10px;
				position: relative;
			}
		}
		>section{
			&+div{
				.imageModal{
					top: 40% !important;
				}
			}
		}
	}
}