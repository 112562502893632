/*! purgecss start ignore */
#stoneleigh-page-builder-container{
	.content-row{
        h1, h2, h3, h4{
            color: $blueDark;
        }
        ol, ul{
            >li{
                &:before{
                    background: $blueDark;
                }
            }
        }
		.page-builder-image-caption{
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 20px;
			img{
				height: 24px;
			}
			a{
				cursor: pointer;
			}
		}
		&.accordions-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			.accordion-item{
				margin-bottom: 10px;
				.accordion-header{
					margin-bottom: 0;
					line-height: 1;
					button{
						color: $brownDark;
						text-decoration: none;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						margin-bottom: 5px;
						border: none;
						background: transparent;
						text-align: left;
						width: 100%;
						&[aria-expanded="true"]{
							>div{
								&:first-child{
									.icon-plus{
										opacity: 0.7;
										@include transition(all, 300ms, ease-in-out);
									}
								}
							}
						}
						>div{
							&:first-child{
								width: 30px;
								.icon-plus{
									font-size: 24px;
									position: relative;
									top: 2px;
									opacity: 1;
                                    color: $blueDark;
									@include transition(all, 300ms, ease-in-out);
									&:before{
										content: '\e909';
									}
								}
							}
							&:last-child{
								width: calc(100% - 30px);
								padding-left: 5px;
								.accordion-title{
									margin-bottom: 0;
									color: $blueDark;
									font-size: 24px;
								}
							}
						}
					}
				}
				.accordion-body{
					padding-left: 40px;
					p:last-child{
						margin-bottom: 10px;
					}
				}
			}
		}
		&.call-to-action-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			.call-to-action-content{
				position: relative;
				z-index: 10;
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					>div{
						&:first-child{
							width: 60%;
							h2{
								font-weight: normal;
								strong{
									font-weight: normal;
								}
							}
						}
						&:last-child{
							width: 40%;
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							justify-content: flex-end;
							>div{
								&:not(:last-of-type){
									margin-right: 30px;
								}
							}
						}
					}
				}
			}
		}
		&.content-type{
			padding: 80px 0 40px;
			iframe[src^="https://www.youtube.com"] {
				width: 100%;
				height: 640px;
			}
		}
		&.events-type{
			background-color: $blueLight;
			padding: 80px 0;
			overflow: hidden;
			.events-carousel-header{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				@include align-center;
				margin-bottom: 50px;
				>div{
					&:first-child{
						width: 70%;
						h2{
							margin-bottom: 0;
						}
					}
					&:last-child{
						width: 30%;
						text-align: right;
						.button.arrow{
							font-weight: normal;
                            color: $blueDark;
                            &:hover{
                                color: $blueDark;
                            }
						}
					}
				}
			}
			.swiper{
				overflow: visible;
				clip-path: inset(-100vw -100vw -100vw 0vw);
			}
			.swiper-slide{
				background-color: $offwhite;
				height: auto;
				.image-wrapper{
					height: 180px !important;
					position: relative;
					img{
						height: 180px !important;
						object-fit: cover !important;
					}
				}
				a{
					text-decoration: none;
				}
				.event-content{
					padding: 16px;
					h4{
						margin-bottom: 15px;
						color: $brownLight;
					}
					.event-category{
						border: 1px solid $warmGray;
						font-size: 14px;
						display: inline-block;
						padding: 5px 15px;
						color: $maroon;
						p{
							margin-bottom: 0;
							color: $maroon;
						}
					}
					.event-list-details{
						margin-top: 15px;
						span{
							margin: 0 2.5px 0 5px;
						}
					}
					p{
						font-size: 16px;
						color: $brownLight;
						margin-bottom: 0;
					}
				}
			}
			.events-navigation{
				text-align: center;
				margin-top: 60px;
				&.hide-desktop{
					display: none;
				}
				>div{
					display: inline-block;
					vertical-align: middle;
					cursor: pointer;
					&.prev-arrow{
						margin-right: 20px;
						.icon-arrow{
                            color: $blueDark;
							display: inline-block;
							@include rotate(180deg);
							position: relative;
							&:before{
								content: '\e90a';
							}
						}
					}
					&.next-arrow{
						.icon-arrow{
                            color: $blueDark;
							&:before{
								content: '\e90a';
							}
						}
					}
				}
			}
		}
		&.featured-event-type{
			padding: 80px 0;
			>div{
				background-color: $blueLight;
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					>div{
						&:first-child{
							width: 45%;
							position: relative;
							.image-wrapper{
								height: 300px !important;
								img{
									height: 300px !important;
									object-fit: cover !important;
								}
							}
						}
						&:last-child{
							width: 55%;
							padding: 25px 50px;
							.event-category{
								background: $offwhite;
								padding: 7px 15px;
								display: inline-block;
								font-size: 14px;
								margin-bottom: 15px;
								p{
									margin-bottom: 0;
									color: $maroon;
								}
							}
							.event-list-details{
								p{
									margin-bottom: 0;
								}
								span{
									margin: 0 2.5px 0 5px;
								}
							}
							h4{
								margin-bottom: 15px;
							}
							p{
								margin-bottom: 20px;
								font-size: 16px;
							}
                            .button.red{
                                @include blue-border-button;
                            }
						}
					}
				}
			}
		}
		&.featured-news-type{
			padding: 80px 0;
			>div{
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					border: 1px solid $warmGray;
					>div{
						&:first-child{
							width: 45%;
							position: relative;
							.image-wrapper{
								height: 324px !important;
								img{
									height: 324px !important;
									object-fit: cover !important;
								}
							}
						}
						&:last-child{
							width: 55%;
							padding: 0 50px;
							h4{
								margin-bottom: 10px;
							}
							p{
								margin-bottom: 20px;
								font-size: 16px;
							}
                            .button.red{
                                @include blue-border-button;
                            }
						}
					}
				}
			}
		}
		&.full-width-image-type{
			position: relative;
			height: 640px;
			img{
				object-fit: cover;
				height: 640px !important;
			}
			.full-width-image-caption{
				width: 100%;
				padding: 0 50px 50px;
				position: absolute;
				bottom: 0;
				left: 0;
				img{
					height: 24px !important;
				}
				a{
					cursor: pointer;
				}
			}
		}
		&.five-column-logos-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			.logos-container{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 50px;
				.logo{
					width: 18%;
					text-align: left;
					padding: 0 20px;
					&:nth-of-type(5n), &:nth-of-type(5n-1), &:nth-of-type(5n-2), &:nth-of-type(5n-3){
						margin-left: 2.5%;
					}
					.image-wrapper{
						height: 145px;
						position: relative;
						margin-bottom: 20px;
						text-align: center;
						img{
							height: 145px !important;
							object-fit: contain !important;
						}
					}
					h4{
						margin-bottom: 10px;
					}
				}
			}
		}
		&.form-type{
			padding: 80px 0;
		}
		&.four-column-logos-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			.logos-container{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 50px;
				.logo{
					padding: 0 20px;
					width: 22.5%;
					text-align: left;
					&:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2){
						margin-left: 3.333%;
					}
					.image-wrapper{
						height: 175px;
						position: relative;
						margin-bottom: 20px;
						text-align: center;
						img{
							height: 175px !important;
							object-fit: contain !important;
						}
					}
					h4{
						margin-bottom: 10px;
					}
				}
			}
		}
		&.four-column-widgets-type{
			padding: 80px 0;
			text-align: center;
			&.gray-background{
				background: $warmGray;
			}
			.widget-container{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 50px;
				.widget{
					width: 24%;
					text-align: left;
					display: flex;
					flex-direction: column;
					&:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2){
						margin-left: 1.333%;
					}
					.image-wrapper{
						height: 175px;
						position: relative;
						margin-bottom: 20px;
						width: 100%;
						img{
							height: 175px !important;
							object-fit: cover !important;
							width: 100%;
						}
						.page-builder-image-caption{
							img{
								height: 24px !important;
							}
						}
					}
					.button-container{
						margin-top: auto;
					}
					h3, h4{
						margin-bottom: 10px;
					}
					h3{
						font-family: $secondaryFont;
						font-size: 24px;
						line-height: 1.5;
						margin-bottom: 20px;
					}
				}
			}
		}
		&.gallery-type{
			background-color: $offwhite;
			>div{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				>div{
					width: 50%;
					position: relative;
					&:first-child{
						padding: 200px 80px 200px 20px;
						>div{
							width: 100%;
							float: right;
							max-width: 550px;
                            .button.red{
                                @include blue-button;
                            }
						}
					}
					&:last-child{
						span{
							img{
								object-fit: cover;
								cursor: default !important;
							}
						}
						.gallery-background-image{
							position: absolute;
							width: 100%;
							height: 100%;
							z-index: 100;
						}
						.gallery-caption-container{
							width: 100%;
							padding: 0 50px 30px;
							position: absolute;
							bottom: 0;
							left: 0;
						}
					}
				}
			}
		}
		&.header-type{
			padding: 80px 0;
			&.gray-background{
				background-color: $warmGray;
			}
			&.blue-background{
				background: $blueDark;
				h1, h2, h3, h4, h5, h6, p{
					color: $white;
				}
			}
			p{
				margin-bottom: 0;
			}
		}
		&.left-image-right-content-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			>div{
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					>div{
						position: relative;
						width: 50%;
						&:first-child{
							.image-wrapper{
								width: 100%;
								height: 300px;
								img{
									object-fit: cover;
									height: 300px !important;
								}
								.page-builder-image-caption{
									img{
										height: 24px !important;
									}
								}
							}
							.image-caption{
								display: flex;
								flex-direction: row;
								flex-wrap: wrap;
								@include align-center;
								margin-top: 20px;
								>div{
									&:first-child{
										width: 30px;
										img{
											position: relative;
											top: -1.5px;
										}
									}
									&:last-child{
										width: calc(100% - 30px);
										p{
											margin-bottom: 0;
											font-size: 16px;
										}
									}
								}
							}
						}
						&:last-child{
							padding-left: 100px;
						}
					}
				}
			}
		}
		&.left-image-square-right-content-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			>div{
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					>div{
						position: relative;
						width: 50%;
						&:first-child{
							.image-wrapper{
								width: 100%;
								height: 530px;
								img{
									object-fit: cover;
									height: 530px !important;
								}
							}
							.image-caption{
								display: flex;
								flex-direction: row;
								flex-wrap: wrap;
								@include align-center;
								margin-top: 20px;
								>div{
									&:first-child{
										width: 30px;
										img{
											position: relative;
											top: -1.5px;
										}
									}
									&:last-child{
										width: calc(100% - 30px);
										p{
											margin-bottom: 0;
											font-size: 16px;
										}
									}
								}
							}
						}
						&:last-child{
							padding-left: 100px;
						}
					}
				}
			}
		}
		&.right-image-left-content-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			>div{
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					>div{
						position: relative;
						width: 50%;
						&:first-child{
							padding-right: 100px;
						}
						&:last-child{
							.image-wrapper{
								width: 100%;
								height: 300px;
								img{
									object-fit: cover;
									height: 300px !important;
								}
								.page-builder-image-caption{
									img{
										height: 24px !important;
									}
								}
							}
							.image-caption{
								display: flex;
								flex-direction: row;
								flex-wrap: wrap;
								@include align-center;
								margin-top: 20px;
								>div{
									&:first-child{
										width: 30px;
										img{
											position: relative;
											top: -1.5px;
										}
									}
									&:last-child{
										width: calc(100% - 30px);
										p{
											margin-bottom: 0;
											font-size: 16px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.right-image-square-left-content-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			>div{
				>div{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					@include align-center;
					>div{
						position: relative;
						width: 50%;
						&:first-child{
							padding-right: 100px;
						}
						&:last-child{
							.image-wrapper{
								width: 100%;
								height: 530px;
								img{
									object-fit: cover;
									height: 530px !important;
								}
							}
							.image-caption{
								display: flex;
								flex-direction: row;
								flex-wrap: wrap;
								@include align-center;
								margin-top: 20px;
								>div{
									&:first-child{
										width: 30px;
										img{
											position: relative;
											top: -1.5px;
										}
									}
									&:last-child{
										width: calc(100% - 30px);
										p{
											margin-bottom: 0;
											font-size: 16px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.six-column-logos-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			.logos-container{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 50px;
				.logo{
					width: 15%;
					text-align: left;
					padding: 0 20px;
					&:nth-of-type(6n), &:nth-of-type(6n-1), &:nth-of-type(6n-2), &:nth-of-type(6n-3), &:nth-of-type(6n-4){
						margin-left: 2%;
					}
					.image-wrapper{
						height: 115px;
						position: relative;
						margin-bottom: 20px;
						text-align: center;
						img{
							height: 115px !important;
							object-fit: contain !important;
						}
					}
					h4{
						margin-bottom: 10px;
					}
				}
			}
		}
		&.three-column-logos-type{
			padding: 80px 0;
			&.gray-background{
				background: $warmGray;
			}
			.logos-container{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				@include align-center;
				margin-top: 50px;
				.logo{
					width: 31%;
					text-align: left;
					&:nth-of-type(3n-1){
						margin-left: 3.333%;
						margin-right: 3.333%;
					}
					.image-wrapper{
						height: 200px;
						position: relative;
						margin-bottom: 20px;
						text-align: center;
						img{
							height: 200px !important;
							object-fit: cover !important;
						}
					}
					h4{
						margin-bottom: 10px;
					}
				}
			}
		}
		&.three-column-widgets-type{
			padding: 80px 0;
			text-align: center;;
			&.gray-background{
				background: $warmGray;
			}
			.widget-container{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 50px;
				.widget{
					width: 33.333%;
					text-align: left;
					padding: 0 10px;
					display: flex;
					flex-direction: column;
					.image-wrapper{
						height: 240px;
						position: relative;
						margin-bottom: 20px;
						width: 100%;
						img{
							height: 240px !important;
							object-fit: cover !important;
							width: 100%;
						}
						.page-builder-image-caption{
							img{
								height: 24px !important;
							}
						}
					}
					.button-container{
						margin-top: auto;
					}
					h3, h4{
						margin-bottom: 10px;
					}
					h3{
						font-family: $secondaryFont;
						font-size: 24px;
						line-height: 1.5;
						margin-bottom: 20px;
					}
				}
			}
		}
		&.two-column-widgets-type{
			padding: 80px 0;
			text-align: center;;
			&.gray-background{
				background: $warmGray;
			}
			.widget-container{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 50px;
				.widget{
					width: 48%;
					text-align: left;
					display: flex;
					flex-direction: column;
					&:nth-of-type(2n){
						margin-left: 4%;;
					}
					.image-wrapper{
						height: 300px;
						position: relative;
						margin-bottom: 20px;
						img{
							height: 300px !important;
							object-fit: cover !important;
						}
						.page-builder-image-caption{
							img{
								height: 24px !important;
							}
						}
					}
					.button-container{
						margin-top: auto;
					}
					h3, h4{
						margin-bottom: 10px;
					}
					h3{
						font-family: $secondaryFont;
						font-size: 24px;
						line-height: 1.5;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1340px){
	#stoneleigh-page-builder-container{
		.content-row{
			&.four-column-widgets-type{
				padding: 80px 20px;
			}
			&.three-column-widgets-type{
				padding: 80px 20px;
			}
		}
	}
}

@media screen and (max-width: 1120px){
	#stoneleigh-page-builder-container{
		.content-row{
			&.accordions-type{
				padding: 80px 20px;
			}
			&.call-to-action-type{
				padding: 80px 20px;
			}
			&.content-type{
				padding: 80px 20px 40px;
			}
			&.form-type{
				padding: 80px 20px;
			}
			&.full-width-image-type{
				.full-width-image-caption{
					padding: 0 20px 20px;
				}
			}
			&.five-column-logos-type{
				padding: 80px 20px;
			}
			&.four-column-logos-type{
				padding: 80px 20px;
			}
			&.events-type{
				padding: 80px 20px;
			}
			&.featured-event-type{
				padding: 80px 20px;
			}
			&.featured-news-type{
				padding: 80px 20px;
			}
			&.header-type{
				padding: 80px 20px;
			}
			&.left-image-square-right-content-type{
				padding: 80px 20px;
				>div{
					>div{
						>div{
							&:last-child{
								padding-left: 30px;
							}
						}
					}
				}
			}
			&.left-image-right-content-type{
				padding: 80px 20px;
				>div{
					>div{
						>div{
							&:last-child{
								padding-left: 30px;
							}
						}
					}
				}
			}
			&.right-image-square-left-content-type{
				padding: 80px 20px;
				>div{
					>div{
						>div{
							&:first-child{
								padding-right: 30px;
							}
						}
					}
				}
			}
			&.right-image-left-content-type{
				padding: 80px 20px;
				>div{
					>div{
						>div{
							&:first-child{
								padding-right: 30px;
							}
						}
					}
				}
			}
			&.six-column-logos-type{
				padding: 80px 20px;
			}
			&.three-column-logos-type{
				padding: 80px 20px;
			}
			&.two-column-widgets-type{
				padding: 80px 20px;
			}
		}
	}
}

@media screen and (max-width: 1024px){
	#page-builder-container{
		.content-row{
			&.events-type{
				.events-navigation{
					&.hide-desktop{
						display: block;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 950px){
	#stoneleigh-page-builder-container{
		.content-row{
			&.call-to-action-type{
				.call-to-action-content{
					>div{
						>div{
							&:first-child, &:last-child{
								width: 100%;
							}
							&:last-child{
								padding: 20px 0 0;
								justify-content: center;
							}
						}
					}
				}
			}
			&.content-type{
				iframe[src^="https://www.youtube.com"] {
					height: 450px;
				}
			}
			&.gallery-type{
				>div{
					flex-direction: column-reverse;
					>div{
						width: 100%;
						&:first-child{
							padding: 60px 20px;
							>div{
								width: 100%;
								max-width: 100%;
							}
						}
						&:last-child{
							height: 400px;
							.gallery-caption-container{
								padding: 0 20px 20px;
							}
						}
					}
				}
			}
			&.featured-event-type{
				>div{
					>div{
						>div{
							&:first-child, &:last-child{
								width: 100%;
							}
							&:last-child{
								padding: 25px;
							}
						}
					}
				}
			}
			&.featured-news-type{
				>div{
					>div{
						>div{
							&:first-child, &:last-child{
								width: 100%;
							}
							&:last-child{
								padding: 25px;
							}
						}
					}
				}
			}
			&.four-column-widgets-type{
				.widget-container{
					.widget{
						width: 100%;
						&:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2){
							margin: 50px 0 0;
						}
						.image-wrapper{
							height: 220px;
							img{
								height: 220px !important;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px){
	#stoneleigh-page-builder-container{
		.content-row{
			&.accordions-type{
				padding: 60px 20px;
				.accordion-item{
					.accordion-header{
						button{
							>div{
								&:last-child{
									.accordion-title{
										font-size: 20px;
									}
								}
							}
						}
					}
				}
			}
			&.call-to-action-type{
				.call-to-action-content{
					>div{
						>div{
							&:last-child{
								>div{
									width: 100%;
									&:not(:last-of-type){
										margin: 0 0 20px;
									}
								}
							}
						}
					}
				}
			}
			&.content-type{
				padding: 60px 20px 20px;
				iframe[src^="https://www.youtube.com"] {
					height: 220px;
				}
				.button{
					margin-bottom: 15px;
				}
			}
			&.featured-event-type{
				padding: 60px 20px;
			}
			&.featured-news-type{
				padding: 60px 20px;
			}
			&.five-column-logos-type{
				padding: 60px 20px;
				.logos-container{
					.logo{
						padding: 0 20px;
						width: 33.3333%;
						&:nth-of-type(5n), &:nth-of-type(5n-1), &:nth-of-type(5n-2), &:nth-of-type(5n-3){
							margin-left: 0;;
						}
					}
				}
			}
			&.four-column-widgets-type{
				padding: 60px 20px;
				.widget-container{
					.widget{
						h3{
							font-size: 20px;
						}
					}
				}
			}
			&.four-column-logos-type{
				padding: 60px 20px;
				.logos-container{
					.logo{
						padding: 0 20px;
						width: 50%;
						&:nth-of-type(4n), &:nth-of-type(4n-1), &:nth-of-type(4n-2){
							margin-left: 0;
						}
					}
				}
			}
			&.full-width-image-type{
				height: 250px;
				img{
					height: 250px;
				}
			}
			&.gallery-type{
				>div{
					>div{
						&:first-child{
							button{
								width: 100%;
								max-width: 500px;
							}
						}
						&:last-child{
							height: 250px;
						}
					}
				}
			}
			&.header-type{
				padding: 50px 20px;
			}
			&.left-image-square-right-content-type{
				padding: 60px 20px;
				>div{
					>div{
						>div{
							&:first-child, &:last-child{
								width: 100%;
							}
							&:first-child{
								.image-wrapper{
									height: 320px;
									img{
										height: 320px !important;
									}
								}
							}
							&:last-child{
								padding: 30px 0 0;
							}
						}
					}
				}
			}
			&.left-image-right-content-type{
				padding: 60px 20px;
				>div{
					>div{
						>div{
							&:first-child, &:last-child{
								width: 100%;
							}
							&:first-child{
								.image-wrapper{
									height: 220px;
									img{
										height: 220px !important;
									}
								}
							}
							&:last-child{
								padding: 30px 0 0;
							}
						}
					}
				}
			}
			&.right-image-square-left-content-type{
				padding: 60px 20px;
				>div{
					>div{
						flex-direction: column-reverse;
						>div{
							&:first-child, &:last-child{
								width: 100%;
							}
							&:first-child{
								padding: 30px 0 0;
							}
							&:last-child{
								.image-wrapper{
									height: 320px;
									img{
										height: 320px !important;
									}
								}
							}
						}
					}
				}
			}
			&.right-image-left-content-type{
				padding: 60px 20px;
				>div{
					>div{
						flex-direction: column-reverse;
						>div{
							&:first-child, &:last-child{
								width: 100%;
							}
							&:first-child{
								padding: 30px 0 0;
							}
							&:last-child{
								.image-wrapper{
									height: 220px !important;
									img{
										height: 220px !important;
									}
								}
							}
						}
					}
				}
			}
			&.six-column-logos-type{
				padding: 60px 20px;
				.logos-container{
					.logo{
						padding: 0 20px;
						width: 33.3333%;
						&:nth-of-type(6n), &:nth-of-type(6n-1), &:nth-of-type(6n-2), &:nth-of-type(6n-3), &:nth-of-type(6n-4){
							margin-left: 0;;
						}
					}
				}
			}
			&.three-column-logos-type{
				padding: 60px 20px;
				.logos-container{
					.logo{
						padding: 0 20px;
						width: 100%;
					}
				}
			}
			&.three-column-widgets-type{
				padding: 60px 20px;
				.widget-container{
					.widget{
						width: 100%;
						&:nth-of-type(3n-1){
							margin: 50px 0;
						}
						.image-wrapper{
							height: 220px;
							img{
								height: 220px !important;
							}
						}
						h3{
							font-size: 20px;
						}
					}
				}
			}
			&.two-column-widgets-type{
				padding: 60px 20px;
				.widget-container{
					.widget{
						width: 100%;
						&:nth-of-type(2n){
							margin: 50px 0 0;
						}
						.image-wrapper{
							height: 220px;
							img{
								height: 220px !important;
							}
						}
						h3{
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}


/*! purgecss end ignore */