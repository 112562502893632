#news-header-container{
    padding: 80px 0;
    background: $warmGray;
    margin-top: 130px;
}

#news-filter-container{
    padding: 40px 0 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $warmGray;
    >div{
        >div{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            >div{
                &:not(:last-of-type){
                    margin-right: 20px;
                }
                a{
                    color: $brownLight;
                    text-decoration: none;
                    text-transform: lowercase;
                    position: relative;
                    &.active, &:hover{
                        &:before{
                            -webkit-transform: scale3d(1, 1, 1);
                            transform: scale3d(1, 1, 1);
                            -webkit-transition-delay: 0s;
                            transition-delay: 0s;
                        }
                    }
                    &:before{
                        background: $brownDark;
                        -webkit-transition-delay: 0.4s;
                        transition-delay: 0.4s;
                        content: '';
                        position: absolute;
                        top: calc(100% + 5px);
                        left: 0;
                        width: 100%;
                        height: 2px;
                        -webkit-transform: scale3d(0, 1, 1);
                        transform: scale3d(0, 1, 1);
                        -webkit-transform-origin: center left;
                        transform-origin: center left;
                        -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                        transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                    }
                    &:hover{
                        color: $brownDark;
                    }
                }
            }
        }
    }
}

#news-list-container{
    .news-list-item{
        padding: 35px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include align-center;
        >div{
            &:first-child{
                width: 45%;
                a{
                    display: inline-block;
                    width: 100%;
                }
                img{
                    object-fit: cover;
                    width: 100%;
                }
            }
            &:last-child{
                width: 55%;
                padding-left: 50px;
                h4{
                    margin-bottom: 10px;
                }
                p{
                    margin-bottom: 20px;
                }
            }
        }
    }
}

#news-list-pagination{
    border-top: 1px solid $warmGray;
    padding: 30px 0 80px;
    margin-top: 80px;
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            >div{
                width: 50%;
                &:last-child{
                    text-align: right;
                }
                button{
                    @include red-button;
                }
            }
        }
    }
}

#single-news-header-container{
    background: $warmGray;
    padding: 80px 0 180px;
    margin-top: 130px;
    p{
        margin-bottom: 0;
    }
}

#single-news-content{
    top: -120px;
    margin-bottom: -60px;
    #single-news-image{
        width: 100%;
        height: 573px;
        margin-bottom: 50px;
        position: relative;
        >span{
            height: 573px !important;
        }
        img{
            object-fit: cover;
        }
        #single-news-image-caption{
            width: 100%;
            padding: 20px;
            position: absolute;
            bottom: 0;
            left: 0;
            img{
                height: 24px !important;
            }
            a{
                cursor: pointer;
            }
        }
    }
    #single-news-description{
        padding: 0 0 30px;
        margin: -35px 0 0;
        font-style: italic;
        font-size: 16px;
    }
}

#next-news-post-container{
    padding: 40px 0;
    margin-bottom: 80px;
    h2{
        margin-bottom: 40px;
    }
    >div{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include align-center;
        >div{
            &:first-child{
                width: 45%;
                >span{
                    width: 100% !important;
                    height: 324px !important;
                }
                img{
                    object-fit: cover;
                    height: 324px !important;
                }
            }
            &:last-child{
                width: 55%;
                padding-left: 50px;
                p{
                    margin: 0 0 10px;
                }
                .excerpt{
                    margin: 0 0 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1240px){
    #news-header-container{
        margin-top: 0;
    }
    #single-news-header-container{
        margin-top: 0;
    }
}

@media screen and (max-width: 1120px){
    #news-header-container{
        padding: 80px 20px;
    }
    #news-filter-container{
        padding: 40px 20px;
    }
    #news-list-wrapper{
        padding: 0 20px;
    }
    #next-news-post-container{
        padding: 40px 20px;
    }
}

@media screen and (max-width: 950px){
    #news-list-container{
        .news-list-item{
            >div{
                &:first-child, &:last-child{
                    width: 100%;
                }
                &:first-child{
                    a{
                        span{
                            width: 100% !important;
                            img{
                                object-fit: cover;
                            }
                        }
                    }
                }
                &:last-child{
                    padding: 30px 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 900px){
    #single-news-header-container{
        padding: 80px 20px 180px;
    }
    #single-news-content{
        padding: 0 20px;
    }
}

@media screen and (max-width: 850px){
    #next-news-post-container{
        >div{
            >div{
                &:first-child, &:last-child{
                    width: 100%;
                }
                &:last-child{
                    padding: 30px 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    #news-header-container{
        padding: 40px 20px;
    }
    #news-filter-container{
        padding: 30px 20px;
        overflow-x: scroll;
        >div{
            width: 900px;
        }
    }
    #news-list-pagination{
        margin-top: 50px;
        >div{
            >div{
                >div{
                    button{
                        padding: 12px 15px;
                        min-width: 130px;
                    }
                }
            }
        }
    }
    #single-news-header-container{
        padding: 50px 20px 120px;
    }
    #single-news-content{
        top: -120px;
        margin-bottom: -120px;
        #single-news-image{
            width: 100%;
            height: 255px;
            margin-bottom: 30px;
            >span{
                height: 255px !important;
            }
        }
    }
    #single-news-description{
        margin: -30px 0 0;
    }
    #next-news-post-container{
        margin-bottom: 40px;
        >div{
            >div{
                &:first-child{
                    >span{
                        height: 250px !important;
                        img{
                            height: 250px !important;
                        }
                    }
                }
                &:last-child{
                    padding: 15px 0 0;
                }
            }
        }
    }
}