#search-header-container{
    background: $warmGray;
    padding: 80px 0;
    margin-top: 130px;
    >div{
        display: table;
        width: 100%;
        height: 100%;
        >div{
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            >div{
                >div{
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    @include align-center;
                    >div{
                        width: 50%;
                        &:first-child{
                            h1{
                                margin-bottom: 0;
                            }
                        }
                        &:last-child{
                            >div{
                                display: block;
                                margin: 0 0 0 auto;
                                width: 100%;
                                max-width: 420px;
                            }
                        }
                    }
                    label{
                        color: $brownDark;
                        &.visuallyhidden{
                            border: 0;
                            clip-path: rect(0,0,0,0);
                            height: 1px;
                            margin: -1px;
                            overflow: hidden;
                            padding: 0;
                            position: absolute;
                            width: 1px;
                        }
                    }
                    input[type="text"]{
                        border: none;
                        border: 1px solid $brownDark;
                        font-size: 16px;
                        background: $white;
                        width: calc(100% - 70px);
                        height: 55px;
                        border-radius: 0;
                        padding-left: 15px;
                        font-family: $primaryFont;
                        @include transition(all, 300ms, ease-in-out);
                    }
                    button{
                        @include red-button;
                        min-width: 55px;
                        padding: 14.5px;
                        font-family: $secondaryFont;
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }
    }
}

#search-result-toggles{
    padding: 30px 0;
    border-bottom: 1px solid $warmGray;
    >div{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        >div{
            &:not(:last-of-type){
                margin-right: 20px;
            }
            a{
                color: $brownLight;
                text-decoration: none;
                text-transform: lowercase;
                position: relative;
                &.active, &:hover{
                    &:before{
                        -webkit-transform: scale3d(1, 1, 1);
                        transform: scale3d(1, 1, 1);
                        -webkit-transition-delay: 0s;
                        transition-delay: 0s;
                    }
                }
                &:before{
                    background: $brownDark;
                    -webkit-transition-delay: 0.4s;
                    transition-delay: 0.4s;
                    content: '';
                    position: absolute;
                    top: calc(100% + 5px);
                    left: 0;
                    width: 100%;
                    height: 2px;
                    -webkit-transform: scale3d(0, 1, 1);
                    transform: scale3d(0, 1, 1);
                    -webkit-transform-origin: center left;
                    transform-origin: center left;
                    -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                    transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                }
                &:hover{
                    color: $brownDark;
                }
            }
        }
    }
}

#search-results-header{
    padding: 30px 0 0;
    p{
        font-style: italic;
        font-size: 16px;
    }
}

#no-search-results{
    padding: 20px 0 50px;
}

#search-loading-container{
    padding: 50px 20px;
    text-align: center;
    >div{
        display: block;
        margin: 0 auto;
    }
}

#search-result-list{
    padding: 0 0 80px;
    .search-result-item{
        border-bottom: 1px solid $warmGray;
        padding: 20px 0;
        a{
            color: $brownDark;
            text-decoration: none;
            &:hover{
                color: $maroon;
            }
            p{
                margin-bottom: 0;
                font-weight: bold;
            }
        }
        .excerpt{
            p{
                margin: 10px 0 0;
            }
        }
    }
}

@media screen and (max-width: 1240px){
    #search-header-container{
        margin-top: 0;
    }
}

@media screen and (max-width: 1220px){
    #search-header-container{
        padding: 80px 20px;
    }
    #search-result-toggles{
        max-width: calc(100% - 40px);
    }
    #search-results-header{
        padding: 30px 20px 0;
    }
    #search-result-list{
        padding: 0 20px 80px;
    }
    #no-search-results{
        padding: 100px 20px 50px;
    }
}

@media screen and (max-width: 767px){
    #search-result-toggles{
        overflow-x: scroll;
        >div{
            width: 500px;
        }
    }
    #search-header-container{
        padding: 50px 20px;
        >div{
            >div{
                >div{
                    >div{
                        >div{
                            width: 100%;
                            &:last-child{
                                padding: 30px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
    #no-search-results{
        padding: 70px 20px 40px;
    }
}