#hero-content{
	padding: 80px 0;
	margin-top: 130px;
}

#hero-slider{
	.swiper-slide{
		.image-wrapper{
			position: relative;
			height: 640px;
			img{
				height: 640px !important;
				object-fit: cover;
			}
		}
		.hero-slider-image-caption{
			width: 100%;
			padding: 0 50px 50px;
			position: absolute;
			bottom: 0;
			left: 0;
			img{
				height: 24px !important;
			}
			a{
				cursor: pointer;
			}
		}
		.hero-image-credit{
			padding: 20px 0 0;
		}
	}
}

#hero-slider-navigation{
	border-bottom: 1px solid $warmGray;
	position: relative;
	z-index: 1000;
	>div{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		>div{
			width: 50%;
			&:last-child{
				text-align: right;
				>div{
					display: inline-block;
					vertical-align: middle;
					cursor: pointer;
					color: $maroon;
					position: relative;
					top: -45px;
					margin-bottom: -35px;
					&.prev-arrow{
						margin-right: 20px;
						.icon-arrow{
							display: inline-block;
							@include rotate(180deg);
							position: relative;
							&:before{
								content: '\e90a';
							}
						}
					}
					&.next-arrow{
						.icon-arrow{
							&:before{
								content: '\e90a';
							}
						}
					}
				}
			}
		}
	}
}

#home-widgets-container{
	padding: 80px 0 40px;
	#home-widgets-content{
		text-align: center;
		margin-bottom: 30px;
		h2{
			margin-bottom: 10px;
		}
		h3{
			color: $brownLight;
		}
	}
	#home-widgets{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 50px;
		.widget{
			width: 32%;
			text-align: left;
			&:nth-of-type(3n-1){
				margin-left: 2%;
				margin-right: 2%;
			}
			.image-wrapper{
				height: 240px;
				position: relative;
				margin-bottom: 20px;
				width: 100%;
				img{
					height: 240px !important;
					object-fit: cover !important;
				}
			}
			h4{
				margin-bottom: 10px;
			}
		}
	}
}

#home-tiles-container{
	padding: 100px 0 60px;
	#home-tiles-background-image{
		position: absolute;
		width: 100%;
		height: calc(100% - 100px);
		object-fit: contain;
		object-position: center bottom;
	}
	#home-tiles-background-image-caption{
        width: 100%;
        padding: 0 50px 20px;
        position: absolute;
        bottom: 0;
        left: 0;
		a{
			cursor: pointer;
		}
    }
	#home-tiles-content{
		>div{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			@include align-center;
			>div{
				width: 50%;
				&:first-child{
					padding: 0 150px;
					#home-tiles-buttons{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						@include align-center;
						margin-top: 20px;
						>div{
							&:not(:last-of-type){
								margin-right: 20px;
							}
						}
					}
				}
				&:last-child{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					>a{
						width: 48%;
						position: relative;
						margin-bottom: 30px;
						&:hover{
							.image-wrapper{
								img{
									transform: scale(1.15);
									@include transition(all, 500ms, ease-in-out);
								}
							}
						}
						&:before{
							position: absolute;
							content: '';
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 49%, rgba(0, 0, 0, 0.9) 100%);
							display: inline-block;
							z-index: 100;
						}
						&:nth-of-type(2n){
							margin-left: 4%;
						}
						.image-wrapper{
							height: 310px;
							position: relative;
							overflow: hidden;
							img{
								height: 310px !important;
								object-fit: cover;
								transform: scale(1.0);
								@include transition(all, 500ms, ease-in-out);
							}
						}
						.tile-content{
							position: absolute;
							bottom: 0;
							left: 0;
							padding: 0 24px 24px;
							text-align: center;
							width: 100%;
							z-index: 100;
							h4{
								color: $white;
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}

#home-events-volunteer-container{
	padding: 100px 0;
	>div{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		>div{
			&:first-child{
				width: 45%;
				.image-wrapper{
					position: relative;
					height: 320px;
					margin-bottom: 20px;
					img{
						height: 320px !important;
						object-fit: cover;
					}
				}
			}
			&:last-child{
				width: 55%;
				padding-left: 100px;
				h2{
					margin-bottom: 50px;
				}
				.home-event-item{
					padding-bottom: 30px;
					&:not(:last-of-type){
						margin-bottom: 30px;
						border-bottom: 1px solid $warmGray;
					}
					.custom-link, h4{
						color: $brownLight;
						margin-bottom: 15px;
					}
					.event-category{
						border: 1px solid $warmGray;
						font-size: 14px;
						display: inline-block;
						padding: 5px 15px;
						color: $maroon;
					}
					.event-list-details{
						margin-top: 15px;
						span{
							margin: 0 2.5px 0 5px;
						}
					}
					p{
						font-size: 16px;
						color: $brownLight;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

#home-events-container{
	background-color: $blueLight;
	padding: 80px 0;
	overflow: hidden;
	#home-events-header{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		@include align-center;
		margin-bottom: 50px;
		>div{
			&:first-child{
				width: 70%;
				h2{
					margin-bottom: 0;
				}
			}
			&:last-child{
				width: 30%;
				text-align: right;
				.button.arrow{
					top: 6px;
					position: relative;
					font-weight: normal;
					color: $brownDark;
				}
			}
		}
	}
	.swiper{
		overflow: visible;
		clip-path: inset(-100vw -100vw -100vw 0vw);
	}
	.swiper-slide{
		background-color: $offwhite;
		height: auto;
		a{
			text-decoration: none !important;
		}
		.image-wrapper{
			height: 180px !important;
			position: relative;
			img{
				height: 180px !important;
				object-fit: cover !important;
			}
		}
		.event-content{
			padding: 16px;
			h4{
				margin-bottom: 15px;
				color: $brownLight;
			}
			.event-category{
				border: 1px solid $warmGray;
				font-size: 14px;
				display: inline-block;
				padding: 5px 15px;
				color: $maroon;
			}
			.event-list-details{
				margin-top: 15px;
				span{
					margin: 0 2.5px 0 5px;
				}
			}
			p{
				font-size: 16px;
				color: $brownLight;
				margin-bottom: 0;
			}
		}
	}
	#home-events-navigation{
		text-align: center;
		margin-top: 60px;
		>div{
			display: inline-block;
			vertical-align: middle;
			cursor: pointer;
			&.prev-arrow{
				margin-right: 20px;
				.icon-arrow{
					display: inline-block;
					@include rotate(180deg);
					position: relative;
					&:before{
						content: '\e90a';
					}
				}
			}
			&.next-arrow{
				.icon-arrow{
					&:before{
						content: '\e90a';
					}
				}
			}
		}
	}
	#mobile-home-events-link{
		display: none;
		padding: 30px 0 0;
		text-align: center;
		.button.arrow{
			color: $brownDark;
		}
	}
}

#home-news-container{
	padding: 100px 0;
	>div{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		>div{
			&:first-child{
				width: 55%;
				padding-right: 60px;
				h2{
					margin-bottom: 50px;
				}
				.home-news-item{
					&:not(:last-of-type){
						margin-bottom: 50px;
					}
					h4{
						color: $brownLight;
					}
				}
			}
			&:last-child{
				width: 45%;
				.image-wrapper{
					position: relative;
					height: 324px;
					margin-bottom: 20px;
					img{
						height: 324px !important;
						object-fit: cover;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1380px){	
	#hero-slider{
		.swiper-slide{
			.hero-slider-image-caption{
				padding: 0 20px 50px;
			}
			.hero-image-credit{
				padding: 20px 20px 0;
			}
		}
	}
	#hero-slider-navigation{
		padding: 0 20px;
	}
	#home-widgets-container{
		padding: 80px 20px 40px;
	}
	#home-tiles-container{
		#home-tiles-content{
			padding: 0 20px;
			>div{
				>div{
					&:first-child{
						padding: 0 60px;
					}
				}
			}
		}
	}
	#home-events-container{
		padding: 80px 20px;
	}
}

@media screen and (max-width: 1240px){
	#hero-content{
		margin-top: 0;
	}
}

@media screen and (max-width: 1120px){
	#hero-content{
		padding: 80px 20px;
	}
	#home-events-volunteer-container{
		padding: 100px 20px;
	}
	#home-news-container{
		padding: 100px 20px;
	}
}

@media screen and (max-width: 900px){
	#home-tiles-container{
		#home-tiles-background-image, #home-tiles-background-image-caption{
			display: none;
		}
		#home-tiles-content{
			>div{
				>div{
					width: 100%;
					&:first-child{
						padding: 0 0 30px;
					}
				}
			}
		}
	}
	#home-events-volunteer-container{
		>div{
			>div{
				&:first-child, &:last-child{
					width: 100%;
				}
					&:last-child{
						padding: 40px 0 0;
					}
			}
		}
	}
	#home-news-container{
		>div{
			>div{
				&:first-child, &:last-child{
					width: 100%;
				}
					&:last-child{
						padding: 40px 0 0;
					}
			}
		}
	}
}

@media screen and (max-width: 767px){
	#hero-content{
		padding: 50px 20px;
		text-align: center;
	}
	#hero-slider{
		.swiper-slide{
			.image-wrapper{
				height: 280px;
				img{
					height: 280px !important;
				}
			}
			.hero-image-credit{
				text-align: center;
			}
			.hero-slider-image-caption{
				padding: 0 20px 20px;
				img{
					height: 24px !important;
				}
			}
		}
	}
	#hero-slider-navigation{
		>div{
			>div{
				width: 100%;
				&:last-child{
					text-align: center;
					padding: 15px 0;
					>div{
						top: 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}
	#home-widgets-container{
		#home-widgets{
			.widget{
				width: 100%;
				&:nth-of-type(3n-1){
					margin: 50px auto;
				}
				.image-wrapper{
					height: 220px;
					img{
						height: 220px !important;
					}
				}
			}
		}
	}
	#home-tiles-container{
		padding: 60px 0 20px;
		#home-tiles-content{
			>div{
				>div{
					&:first-child{
						#home-tiles-buttons{
							>div{
								width: 100%;
								text-align: center;
								&:not(:last-of-type){
									margin-bottom: 20px;
								}
							}
						}
					}
					&:last-child{
						>a{
							width: 100%;
							&:nth-child(2n){
								margin-left: 0;
							}
						}
					}
				}
			}
		}
	}
	#home-events-volunteer-container{
		padding: 60px 20px;
		>div{
			>div{
				&:first-child{
					.image-wrapper{
						height: 250px;
						img{
							height: 250px !important;
						}
					}
				}
			}
		}
	}
	#home-events-container{
		#home-events-header{
			>div{
				&:first-child{
					width: 100%;
				}
				&:last-child{
					display: none;
				}
			}
		}
		#mobile-home-events-link{
			display: block;
		}
	}
	#home-news-container{
		>div{
			>div{
				&:last-child{
					.image-wrapper{
						height: 250px;
						img{
							height: 250px !important;
						}
					}
				}
			}
		}
	}
}