#call-to-action-container{
    &.stoneleigh{
        #call-to-action-content{
            >div{
                >div{
                    &:first-child{
                        h2{
                            color: $blueDark;
                            strong{
                                color: $blueDark;
                            }
                        }
                    }
                }
            }
        }
    }
    &:before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(to bottom, $offwhite 39%, rgba(252, 252, 252, 0) 59%);
        display: inline-block;
        z-index: 10;
    }
    #call-to-action-background-image{
        img{
            object-fit: cover;
            object-position: 50%;
        }
    }
    #call-to-action-content{
        padding: 80px 0 180px;
        border-top: 1px solid $warmGray;
        position: relative;
        z-index: 10;
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include align-center;
            >div{
                &:first-child{
                    width: 60%;
                    h2{
                        font-weight: normal;
                        strong{
                            font-weight: normal;
                            color: $brownLight;
                        }
                    }
                }
                &:last-child{
                    width: 40%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    >div{
                        &:not(:last-of-type){
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }
}
#top-footer-container{
    background: $maroon;
    padding: 80px 0;
    &:before{
        position: absolute;
        content: '';
        background-image: url('../images/footer-background.svg');
        background-size: cover;
        background-position: center bottom;
        left: 0;
        bottom: 0;
        width: 270px;
        height: 370px;
    }
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            >div{
                &:first-child{
                    width: calc(100% - 310px);
                    a{
                        display: inline-block;
                    }
                    >div{
                        margin-top: 20px;
                        &#mobile-address{
                            display: none;
                            p{
                                font-size: 16px;
                            }
                            a{
                                color: $white;
                                text-decoration: underline;
                            }
                        }
                        &#footer-social-container{
                            a{
                                display: inline-block;
                                vertical-align: middle;
                                color: $white;
                                text-decoration: none;
                                font-size: 30px;
                                opacity: 1;
                                &:hover{
                                    opacity: 0.7;
                                }
                                &:not(:last-of-type){
                                    margin-right: 15px;
                                }
                                span{
                                    &.icon-facebook{
                                        &:before{
                                            content: '\e906';
                                        }
                                    }
                                    &.icon-instagram{
                                        &:before{
                                            content: '\e905';
                                        }
                                    }
                                    &.icon-linkedin{
                                        &:before{
                                            content: '\e904';
                                        }
                                    }
                                    &.icon-tiktok{
                                        &:before{
                                            content: '\e903';
                                        }
                                    }
                                }
                            }
                        }
                        &#footer-disclaimer{
                            p{
                                font-size: 16px;
                            }
                        }
                        &#footer-logos{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            >div{
                                &:not(:last-of-type){
                                    margin-right: 20px;
                                }
                                img{
                                    width: 100%;
                                    max-width: 120px;
                                    height: 80px;
                                    object-fit: cover;
                                }
                            }
                        }
                        p{
                            color: $white;
                            line-height: 1.667;
                        }
                    }
                };
                &:last-child{
                    width: 310px;
                    p{
                        color: $white;
                        line-height: 1.667;
                    }
                    .button{
                        @include yellow-button;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

#bottom-footer-container{
    padding: 25px 0;
    background: $offwhite;
    >div{
        >div{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include align-center;
            >div{
                &:first-child, &:last-child{
                    width: 25%;
                    position: absolute;
                    top: 3px;
                    p{
                        font-size: 16px;
                    }
                }
                &:last-child{
                    right: 0;
                    top: -1px;
                    text-align: right;
                    a{
                        font-size: 16px;
                        color: $brownLight;
                        text-decoration: none;
                        &:hover{
                            color: $maroon;
                        }
                        .icon-cacpro{
                            font-size: 18px;
                            margin-left: 5px;
                            position: relative;
                            top: 2px;
                            &:before{
                                content: '\e90e';
                            }
                        }
                    }
                }
                &:nth-child(2){
                    width: 100%;
                    text-align: center;
                    ul{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        >li{
                            display: inline-block;
                            &:not(:last-of-type){
                                margin-right: 20px;
                            }
                            a{
                                font-size: 16px;
                                color: $brownLight;
                                text-decoration: none;
                                &:hover{
                                    color: $maroon;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1380px){
    #bottom-footer-container{
        padding: 25px 20px;
    }
}

@media screen and (max-width: 1120px){
    #call-to-action-container{
        #call-to-action-content{
            max-width: calc(100% - 40px);
        }
    }
    #top-footer-container{
        padding: 80px 20px;
    }
}

@media screen and (max-width: 1000px){
    #call-to-action-container{
        #call-to-action-content{
            >div{
                >div{
                    &:first-child, &:last-child{
                        width: 100%;
                        text-align: center;
                    }
                    &:last-child{
                        padding: 20px 0 0;
                        justify-content: center;
                    }
                }
            }
        }
    }
    #top-footer-container{
        >div{
            >div{
                >div{
                    &:first-child, &:last-child{
                        width: 100%;
                        text-align: center;
                    }
                    &:first-child{
                        >div{
                            &#footer-logos{
                                justify-content: center;
                                >div{
                                    padding: 0 10px;
                                    &:not(:last-of-type){
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                    &:last-child{
                        padding: 50px 0 0;
                    }
                }
            }
        }
    }
    #bottom-footer-container{
        >div{
            >div{
                >div{
                    &:nth-child(1){
                        order: 1;
                        width: 100%;
                        position: relative;
                        text-align: center;
                        top: 0;
                        padding: 10px 0;
                    }
                    &:nth-child(2){
                        order: 0;
                        width: 100%;
                        text-align: center;
                    }
                    &:nth-child(3){
                        width: 100%;
                        position: relative;
                        text-align: center;
                        order: 2;
                        top: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    #call-to-action-container{
        #call-to-action-background-image{
            img{
                object-fit: cover;
            }
        }
        #call-to-action-content{
            >div{
                >div{
                    &:first-child{
                        text-align: left;
                    }
                    &:last-child{
                        >div{
                            width: 100%;
                            &:not(:last-of-type){
                                margin: 0 0 20px;
                            }
                            .button{
                                width: 100%;
                                max-width: 500px;
                            }
                        }
                    }
                }
            }
        }
    }
    #top-footer-container{
        padding: 50px 20px;
        >div{
            >div{
                >div{
                    &:first-child{
                        >div{
                            &#address{
                                display: none;
                            }
                            &#mobile-address{
                                display: block;
                            }
                            &#footer-disclaimer{
                                p{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    &:last-child{
                        .button{
                            width: 100%;
                            max-width: 500px;
                        }
                    }
                }
            }
        }
    }
}